import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'

import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
  ChangePasswordDTO,
  changePasswordViaReset,
  ChangePasswordWithResetDTO,
} from '../api'
import { SidePanel } from '../components/SidePanel'
import { Alert as AlertStore, alertState } from '../store/Alert'
import { userState } from '../store/User'

export function Reset() {
  const navigate = useNavigate()
  const [, setAlert] = useRecoilState<AlertStore>(alertState)
  const [user, setUser] = useRecoilState(userState)

  const bg = useColorModeValue('white', 'gray.700')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordDTO>()
  const { token } = useParams()
  if (!token) {
    navigate('/dashboard')
  }

  const onSubmit = async (data: Partial<ChangePasswordWithResetDTO>) => {
    try {
      const data_with_token = {
        ...data,
        reset_token: token,
      } as ChangePasswordWithResetDTO
      await changePasswordViaReset(data_with_token)
      setUser({})
      navigate('/dashboard')
    } catch (e: any) {
      console.log('error!', e)
      setAlert({
        status: 'error',
        title: 'Something went wrong!',
        description: (
          <Text>
            Please contact{' '}
            <Link textDecor={'underline'} href="mailto:support@listener.fm">
              Support
            </Link>
          </Text>
        ),
      })
    }
  }

  return (
    <SidePanel type="reset">
      <>
        <Heading textAlign={'center'} as="h1" size="lg">
          Reset your password!
          <Divider m={4} />
        </Heading>
        <Container
          rounded={'lg'}
          bg={bg}
          boxShadow={'lg'}
          p={8}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={4}>
            <FormControl id="password" isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                {...register('password', {
                  required:
                    'Password field is required, must be a minimum of 8 characters. ',
                  validate: {
                    matchPattern: (v: string) =>
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
                      'Password must contain at least one uppercase letter, one lowercase letter, and one number',
                  },
                })}
              />
              <FormErrorMessage>
                <>{errors.password?.message}</>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="confirm_password"
              isInvalid={!!errors.confirm_password}
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                {...register('confirm_password', {
                  required:
                    'Confirm Password field is required, must match password field. ',
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match'
                    }
                  },
                })}
              />
              <FormErrorMessage>
                <>{errors.confirm_password?.message}</>
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Stack>
          </Stack>
        </Container>
      </>
    </SidePanel>
  )
}
