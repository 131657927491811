import {
  Container,
  Divider,
  Heading,
  Link as CLink,
  Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { SidePanel } from '../components/SidePanel'

export function Verify() {
  return (
    <SidePanel type="verify">
      <Container width={'fit-content'}>
        <Heading textAlign={'center'} as="h1" size="lg">
          Please check your email to verify your account!
          <Divider m={4} />
          <Text fontSize={'md'}>
            Already clicked the link in your email? <br />
            Try the{' '}
            <CLink textDecoration={'underline'}>
              <Link to="/dashboard" replace={true}>
                dashboard
              </Link>
            </CLink>{' '}
            or contact{' '}
            <CLink textDecor={'underline'} href="mailto:support@listener.fm">
              support
            </CLink>
            .
          </Text>
        </Heading>
      </Container>
    </SidePanel>
  )
}
