import { ChatIcon, SpinnerIcon } from '@chakra-ui/icons'
import {
  Text,
  Button,
  Image,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Center,
  Box,
  VStack,
  HStack,
  Avatar,
  InputRightElement,
  InputGroup,
  Spinner,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router'
import { sendChat } from '../../../api/PodcastApi'
import { Podcast } from '../../../api/types'
import { User } from '../../../store/User'
import { PodcastPropContext } from '../Context'
import { getRawOutput } from '../PodcastView'
import { FiSend } from 'react-icons/fi'
export type MessageOrResponse = 'message' | 'response'
type Role = {
  role: 'user' | 'assistant'
  content: string
}
export type ChatDTO = {
  chat_history: Role[]
}
export function Chat({ renderTitle }: { renderTitle: () => JSX.Element }) {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext)
  const [user]: [User] = useOutletContext()

  const fullName =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : `${user.firstName}`
  const chat = getRawOutput(podcast, 'chat') as ChatDTO[] | undefined
  const [responses, setResponses] = useState<ChatDTO>(
    chat?.length ? chat[0] : { chat_history: [] },
  )
  const [loading, setLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({})
  const onSubmit = async (data: any) => {
    setLoading(true)
    setResponses((cv) => {
      const copy_of_cv = JSON.parse(JSON.stringify(cv))
      copy_of_cv.chat_history.push({ role: 'user', content: data.chat })
      return copy_of_cv
    })
    reset({ chat: null })
    const response = await sendChat({
      fileUUID: podcast.fileUuid,
      prompt: data.chat,
    })
    setLoading(false)
    setResponses(response)
  }
  return (
    <Flex flexDirection="row" flexBasis="100%" width="100%" gap={4}>
      <Box mb={8} w="50%" flex={1}>
        {renderTitle()}
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}
        >
          You can ask questions, you can ask for summaries. Treat it as your own
          post production assistant.
        </Text>
      </Box>
      <Center w="50%" flex={1}>
        <Stack
          spacing={4}
          w={'full'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          border="1px solid"
          borderColor="gray.200"
          p={6}
        >
          <Text fontSize="16px" fontWeight="bold">
            Listener AI
          </Text>
          <Box
            w={'full'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
          >
            <VStack alignItems={'self-start'} w="100%">
              <HStack align="flex-start" spacing={1} w="100%" p={6}>
                <Avatar
                  src={'/logo.png'}
                  w="32px"
                  h="32px"
                  cursor="pointer"
                  mr={2}
                />
                <Text>How can I help you?</Text>
              </HStack>
              {responses?.chat_history?.map((response) => {
                return (
                  <HStack
                    w="100%"
                    spacing={1}
                    align="flex-start"
                    alignItems={'baseline'}
                    borderTop="1px solid"
                    borderColor="gray.200"
                    p={6}
                  >
                    <Heading as="h5" size="sm">
                      <Avatar
                        src={
                          response.role === 'assistant'
                            ? '/logo.png'
                            : user.profilePhotoUrl
                        }
                        w="32px"
                        h="32px"
                        cursor="pointer"
                        mr={2}
                      />
                    </Heading>
                    <Text>{response.content}</Text>
                  </HStack>
                )
              })}
            </VStack>
          </Box>
          <FormControl id="email">
            <Flex>
              <InputGroup>
                <Input
                  {...register('chat', { required: true })}
                  placeholder="Type here..."
                  _placeholder={{ color: 'gray.500' }}
                  type="text"
                  fontSize="14px"
                  onKeyDown={(e) => {
                    console.log(e)
                    if (e.key === 'Enter') {
                      console.log('ENTER KEY HIT')
                      handleSubmit(onSubmit)()
                    }
                  }}
                />
                <InputRightElement
                  children={loading ? <Spinner /> : <FiSend opacity={'.6'} />}
                  cursor="pointer"
                  onClick={handleSubmit(onSubmit)}
                />
              </InputGroup>
              {/* <Button
                onClick={handleSubmit(onSubmit)}
                isDisabled={isSubmitting}
                bg={'teal.400'}
                color={'white'}
                _hover={{
                  bg: 'teal.500',
                }}
              >
                {loading && <Spinner />}
                {!loading && <ChatIcon />}
              </Button> */}
            </Flex>
          </FormControl>
        </Stack>
      </Center>
    </Flex>
  )
}
