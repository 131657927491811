import { Button, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { FaFacebook } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
export function RenderFacebookForm({ children }: any) {
  const { state }: any = useLocation()
  const subscribe = state?.subscribe
  const [isLoading, setLoading] = useState(false)
  const loginWithFacebook = async () => {
    setLoading(true)
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/facebook/login`
  }

  return (
    <VStack spacing={8} align="left">
      <Button
        w="100%"
        background="white"
        borderWidth="1px"
        boxShadow="sm"
        isLoading={isLoading}
        onClick={() => loginWithFacebook()}
        leftIcon={<FaFacebook />}
      >
        {children}
      </Button>
    </VStack>
  )
}
