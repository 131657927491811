import { ChakraProvider, Grid, useToast } from '@chakra-ui/react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import { AuthenticatedRoute } from './auth/AuthenticatedRoute'
import { AuthenticationProvider } from './auth/AuthenticationProvider'
import { UnauthenticatedRoute } from './auth/UnauthenticatedRoute'
import { theme } from './theme'
import { Login } from './components/auth/Login'
import { Register } from './components/auth/Register'
import { Onboarding } from './components/auth/Onboarding'
import { Dashboard } from './pages/Dashboard'
import { HandleCallback } from './pages/HandleCallback'
import { Payment } from './pages/Payment'
import { Settings } from './pages/Settings'
import Footer from './components/Footer'
import { Upload } from './pages/Upload'
import { NotFound } from './pages/NotFound'
import { Logout } from './pages/Logout'
import { PodcastPage } from './pages/PodcastPage'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { Alert as AlertStore, alertState } from './store/Alert'
import { Verify } from './pages/Verify'
import { Reset } from './pages/Reset'
import AppSumo from './pages/AppSumo'

export function App() {
  const [alert] = useRecoilState<AlertStore>(alertState)
  const toast = useToast()

  useEffect(() => {
    alert &&
      toast({
        title: alert.title,
        description: alert.description,
        status: alert.status,
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
  }, [alert, toast])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/auth/:type/callback" element={<HandleCallback />} />
        <Route path="/subscribe" element={<Payment />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/reset/:token" element={<Reset />} />
        <Route path="/appsumo/:code?" element={<AppSumo />} />
        <Route element={<AuthenticationProvider />}>
          <Route path="/" element={<UnauthenticatedRoute />}>
            <Route path="/" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
          </Route>

          <Route element={<AuthenticatedRoute />}>
            <Route path="/podcast/:uuid/:tab?" element={<PodcastPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/dashboard/success"
              element={<Dashboard success={true} />}
            />
            <Route path="/dashboard/:show_id" element={<Dashboard />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/logout" element={<Logout immediate={true} />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </>,
    ),
  )
  return (
    <ChakraProvider theme={theme}>
      <Grid minH={'100vh'}>
        <RouterProvider router={router} />
        {/* <Footer /> */}
      </Grid>
    </ChakraProvider>
  )
}
