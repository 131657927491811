import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Stack,
  useColorModeValue,
  VStack,
  Text,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
  LoginDTO,
  loginWithEmail,
  resetPassword,
  ResetPasswordDTO,
} from '../../api'
import { Alert as AlertStore, alertState } from '../../store/Alert'
import { userState } from '../../store/User'
import { PrimaryButton } from '../shared/PrimaryButton'

export function RenderEmailLogin({ children }: any) {
  const [showForm, setShowForm] = useState(true)
  const bg = useColorModeValue('white', 'gray.700')
  const navigate = useNavigate()
  const [, setAlert] = useRecoilState<AlertStore>(alertState)
  const [_, setUser] = useRecoilState(userState)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginDTO>()
  const onSubmitReset = async (data: ResetPasswordDTO) => {
    try {
      await resetPassword(data)
      setAlert({
        status: 'success',
        title: 'Check your email!',
        description: (
          <Text>
            Please check your email for a link to reset your password!
          </Text>
        ),
      })
    } catch (e: any) {
      setAlert({
        status: 'error',
        title: 'Something went wrong!',
        description: (
          <Text>
            Please contact{' '}
            <Link textDecor={'underline'} href="mailto:support@listener.fm">
              Support
            </Link>
          </Text>
        ),
      })
    }
  }
  const onSubmit = async (data: LoginDTO) => {
    try {
      await loginWithEmail(data)
      setUser({})
      navigate('/dashboard', { replace: true })
    } catch (e: any) {
      if (e.code === AxiosError.ERR_BAD_REQUEST) {
        setAlert({
          status: 'error',
          title: 'Wrong credentials!',
          description: <Text>Please try again!</Text>,
        })
        return
      }
      setAlert({
        status: 'error',
        title: 'Something went wrong!',
        description: (
          <Text>
            Please contact{' '}
            <Link textDecor={'underline'} href="mailto:support@listener.fm">
              Support
            </Link>
          </Text>
        ),
      })
    }
  }

  return (
    <VStack spacing={8} align="left">
      {!showForm ? (
        <Box bg={bg} as="form" onSubmit={handleSubmit(onSubmitReset)}>
          <Stack spacing={4}>
            <FormControl id="email" isInvalid={!!errors.email}>
              <Input
                type="email"
                placeholder="Email address"
                {...register('email', {
                  required: true,
                  validate: {
                    maxLength: (v: string) =>
                      v.length <= 50 ||
                      'The email should have at most 50 characters',
                  },
                })}
              />
              <FormErrorMessage>
                <>{errors.email?.message}</>
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <Button
                bg={'gray.50'}
                color={'gray.400'}
                _hover={{
                  bg: 'blue.500',
                  color: 'white',
                }}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Resetting...' : 'Reset'}
              </Button>

              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-around'}
              >
                <Link
                  color={'black'}
                  size="12"
                  onClick={() => {
                    setShowForm(true)
                  }}
                >
                  Login with Email
                </Link>
                <Link
                  as={RouterLink}
                  to="/register"
                  ml="2"
                  color="black"
                  size="12"
                >
                  Register here
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box bg={bg} as="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <FormControl id="email" isInvalid={!!errors.email}>
              <Input
                type="email"
                placeholder="Email address"
                {...register('email', {
                  required: true,
                  validate: {
                    maxLength: (v: string) =>
                      v.length <= 50 ||
                      'The email should have at most 50 characters',
                  },
                })}
              />
              <FormErrorMessage>
                <>{errors.email?.message}</>
              </FormErrorMessage>
            </FormControl>
            <FormControl id="password" isInvalid={!!errors.password}>
              <Input
                type="password"
                placeholder="Password"
                {...register('password', {
                  required:
                    'Password field is required, must be a minimum of 8 characters. ',
                  minLength: {
                    message: 'Must be a minimum of 8 characters',
                    value: 8,
                  },
                })}
              />
              <FormErrorMessage>
                <>{errors.password?.message}</>
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <PrimaryButton
                isDisabled={isSubmitting}
                primaryText="Login"
                disabledText="Logging in..."
                type="submit"
              />
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-around'}
              >
                <Link
                  color={'black'}
                  onClick={() => {
                    setShowForm(false)
                  }}
                >
                  Forgot password?
                </Link>
                <Link as={RouterLink} to="/register" ml="2" color="black">
                  Register here
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </VStack>
  )
}
