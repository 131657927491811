import {
  Box,
  Center,
  Image,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
  VStack,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  useColorModeValue,
  FormLabel,
} from '@chakra-ui/react'
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'
import {
  APPSUMO_TESTIMONIAL_ID,
  TestimonialCard,
} from '../components/TestimonialCard'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import { RegisterAppSumoDTO, registerUserWithAppSumo } from '../api'
import { PrimaryButton } from '../components/shared'
import { alertState, Alert as AlertStore } from '../store/Alert'
import { userState } from '../store/User'
import { useCallback, useMemo, useState } from 'react'

export default function AppSumo(props: any) {
  const { search } = useLocation()
  const email = useMemo(
    () => new URLSearchParams(search).get('email'),
    [search],
  )

  const [isRegistering, setIsRegistering] = useState(email == null)
  const toggleIsRegistering = useCallback(() => {
    setIsRegistering((x) => !x)
  }, [])
  return (
    <Grid templateColumns="repeat(2, 1fr)">
      <GridItem w="100%">
        <Center h="100%">
          <Box maxW="md" p={8}>
            <VStack spacing={4} w="440px" align="left">
              <Heading letterSpacing="2px" fontSize="2xl">
                Register your AppSumo code
              </Heading>
              <Link onClick={toggleIsRegistering}>
                {isRegistering ? `Existing user?` : `Create an account`}
              </Link>
              <AppSumoForm isRegistering={isRegistering} email={email} />
            </VStack>
          </Box>
        </Center>
      </GridItem>
      <GridItem w="100%" bg="#EAFAFA">
        <Center h="100%">
          <TestimonialCard testimonial_id={APPSUMO_TESTIMONIAL_ID as any} />
        </Center>
        <Box bg="white">Footer</Box>
      </GridItem>
    </Grid>
  )
}

function AppSumoForm({ isRegistering, email }: any) {
  const navigate = useNavigate()
  const [, setAlert] = useRecoilState<AlertStore>(alertState)
  const [user, setUser] = useRecoilState(userState)
  const { code } = useParams()
  const bg = useColorModeValue('white', 'gray.700')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RegisterAppSumoDTO>()

  const onSubmit = async (data: RegisterAppSumoDTO) => {
    try {
      const result = await registerUserWithAppSumo(data)
      if (result.error) {
        setAlert({
          status: 'error',
          title: result.error.message,
          description: <Text />,
        })
        return
      }
      setUser({})
      setAlert({
        status: 'success',
        title:
          'Your AppSumo code has been successfully redeemed. Nice job sumoling!',
        description: <Text />,
      })
      navigate('/dashboard')
    } catch (e: any) {
      if (e.code === AxiosError.ERR_BAD_REQUEST) {
        setAlert({
          status: 'error',
          title: 'Email address already exists!',
          description: (
            <Text>
              Please try a different email address or{' '}
              <Link textDecor={'underline'} href="/login">
                Login here
              </Link>
            </Text>
          ),
        })
        return
      }

      setAlert({
        status: 'error',
        title: 'Something went wrong!',
        description: (
          <Text>
            Please contact{' '}
            <Link textDecor={'underline'} href="mailto:support@listener.fm">
              Support
            </Link>
          </Text>
        ),
      })
    }
  }

  return (
    <VStack spacing={8} align="left">
      <Box rounded={'lg'} bg={bg} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl id="email" isInvalid={!!errors.email}>
            <Input
              placeholder="Email Address"
              type="email"
              {...register('email', {
                value: email,
                required: true,
                validate: {
                  maxLength: (v: string) =>
                    v.length <= 50 ||
                    'The email should have at most 50 characters',
                },
              })}
            />
            <FormErrorMessage>
              <>{errors.email?.message}</>
            </FormErrorMessage>
          </FormControl>
          {!isRegistering ? null : (
            <>
              <HStack>
                <FormControl id="first_name" isInvalid={!!errors.first_name}>
                  <Input
                    placeholder="First name"
                    type="text"
                    {...register('first_name', {
                      required:
                        'First name field is required, must be a minimum of 3 characters. ',
                      minLength: {
                        message: 'Must be a minimum of 3 characters',
                        value: 3,
                      },
                    })}
                  />
                  <FormErrorMessage>
                    <>{errors.first_name?.message}</>
                  </FormErrorMessage>
                </FormControl>
                <FormControl id="last_name" isInvalid={!!errors.last_name}>
                  <Input
                    placeholder="Last name"
                    type="text"
                    {...register('last_name')}
                  />
                  <FormErrorMessage>
                    <>{errors.last_name?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl id="password" isInvalid={!!errors.password}>
                <Input
                  placeholder="Password"
                  type="password"
                  {...register('password', {
                    validate: {
                      matchPattern: (v?: string) =>
                        v
                          ? /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v)
                          : true ||
                            'Password must contain at least one uppercase letter, one lowercase letter, and one number',
                    },
                  })}
                />
                <FormErrorMessage>
                  <>{errors.password?.message}</>
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="confirm_password"
                isInvalid={!!errors.confirm_password}
              >
                <Input
                  placeholder="Confirm password"
                  type="password"
                  {...register('confirm_password', {
                    validate: (val?: string) => {
                      if (watch('password') !== val) {
                        return 'Your passwords do no match'
                      }
                    },
                  })}
                />
                <FormErrorMessage>
                  <>{errors.confirm_password?.message}</>
                </FormErrorMessage>
              </FormControl>
            </>
          )}
          <FormControl id="appsumo_code" isInvalid={!!errors.appsumo_code}>
            <FormLabel>AppSumo Code</FormLabel>
            <Input
              placeholder="AppSumo Code"
              {...register('appsumo_code', {
                required: 'AppSumo Code is required. ',
                value: code,
              })}
            />
            <FormErrorMessage>
              <>{errors.confirm_password?.message}</>
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={4}>
            <PrimaryButton
              isDisabled={isSubmitting}
              primaryText="Submit"
              disabledText="Submitting..."
              type="submit"
            />
          </Stack>
        </Stack>
      </Box>
    </VStack>
  )
}
