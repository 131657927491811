import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { VStack, FormControl, InputGroup, InputLeftAddon, Input, Button, Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";

export function EditSpeakerNames({ isOpen, onOpen, onClose, names, setNames }: { isOpen: boolean, onOpen: () => void, onClose: () => void, names: string[], setNames: Dispatch<SetStateAction<string[]>> }): JSX.Element {
  const [forms, setForms] = useState<string[]>([...names])
  if (forms.length === 0) {
    setForms(['1', '2'])
  }
  const addSpeakerInput = (speaker_name?: string) => {
    const copy_of_forms = [...forms, speaker_name || 'Speaker ' + (forms.length + 1)]
    setForms(copy_of_forms)
  }

  const saveSpeakers = () => {
    const copy_of_forms = [...forms]
    setNames(copy_of_forms)
    return onClose()
  }
  const updateSpeakers = (index: number, value: string) => {
    const copy_of_forms = [...forms]
    copy_of_forms[index] = value
    setForms(copy_of_forms)
  }

  const removeSpeakerInput = (index: number) => {
    const copy_of_forms = [...forms]
    copy_of_forms.splice(index, 1);
    setForms(copy_of_forms)
  }

  // useEffect(() => {
  //   // for (let i = 0; i < total_speakers.length; i++) {
  //   //   addSpeakerInput(total_speakers[i])
  //   // }

  //   // if (!forms.length) {
  //   //   addSpeakerInput()
  //   // }

  // }, [hostNames, guestNames])
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit speaker labels</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box borderWidth="1px" borderRadius="2xl" p={8}>
            <VStack spacing={4} w="500" align="left">
              <FormControl
              >
                {forms.map((value, index) => (
                  <>
                    <InputGroup p={2}>
                      <InputLeftAddon
                        border="1px"
                        borderColor="whiteAlpha.300"
                        bg="transparent"
                        children={`Speaker ${index + 1}`}
                      />
                      <Input
                        value={forms[index]}
                        onChange={(e) => updateSpeakers(index, e.target.value)}
                        autoCapitalize="false"
                        autoComplete="false"
                      />
                      <Button onClick={(e) => removeSpeakerInput(index)} variant={"ghost"} _hover={{}}>
                        <CloseIcon height={"7px"}></CloseIcon>
                      </Button>
                    </InputGroup>
                  </>
                ))}
                <InputGroup size={"xs"} justifyContent={"flex-end"}>
                  <Button onClick={(e) => addSpeakerInput('')} >
                    <AddIcon width={"10px"} height={"7px"} />
                    Add Speaker
                  </Button>
                </InputGroup>
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={saveSpeakers}>
            Save
          </Button>
          <Button variant={"ghost"} _hover={{ background: 'gray.100' }} onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal >

  )
}