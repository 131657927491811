import {
  Text,
  Button,
  Td,
  Tbody,
  Th,
  Thead,
  Tr,
  Table,
  Box,
} from '@chakra-ui/react'
import { useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { getRawOutput } from '../PodcastView'
import { Regenerate } from '../Regenerate'

export function Summary({ renderTitle }: { renderTitle: () => JSX.Element }) {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext)

  const summary = getRawOutput(podcast, 'summaries')
  if (!summary) {
    return null
  }

  const results = (po: string[]) =>
    po.map((summary_raw: string, idx: number) => (
      <Tr>
        <Td>{idx + 1}</Td>
        <Td>
          <Text>{summary_raw}</Text>
        </Td>
        <Td>
          <CopyToClipboard text={summary_raw}>
            <Button variant={'outline'}>
              {' '}
              <FaRegCopy />{' '}
              <Text fontSize={'sm'} p={'2'} pr={0}>
                Copy
              </Text>
            </Button>
          </CopyToClipboard>
        </Td>
      </Tr>
    ))

  const table = summary
    .map((po: string[]) => (
      <>
        <Thead borderTop="1px solid" borderColor="gray.200">
          <Tr>
            <Th>#</Th>
            <Th>Summary</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>{results(po)}</Tbody>
      </>
    ))
    .reverse()

  return (
    <Box width="100%">
      <Box mb={8}>{renderTitle()}</Box>
      <Regenerate
        type={'summary'}
        podcast={podcast}
        placeholder="Specify a prompt to regenerate summaries"
        buttonText="Regenerate summaries"
      />
      <Table mt={8}>{table}</Table>
    </Box>
  )
}
