import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { changePassword, ChangePasswordDTO } from '../../api'
import { Alert as AlertStore, alertState } from '../../store/Alert'
import { PrimaryButton } from '../shared'

export function ChangePassword() {
  const navigate = useNavigate()
  const [, setAlert] = useRecoilState<AlertStore>(alertState)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordDTO>()

  const onSubmit = async (data: ChangePasswordDTO) => {
    try {
      await changePassword(data)
      setAlert({
        status: 'success',
        title: 'Password successfully changed!',
        description: <Text />,
      })
      navigate('/dashboard')
    } catch (e: any) {
      if (e.response.status === 401) {
        setAlert({
          status: 'error',
          title: 'Current password is incorrect!',
          description: <Text>Please try again with the correct password.</Text>,
        })
        return
      }
      if (e.code === AxiosError.ERR_BAD_REQUEST) {
        setAlert({
          status: 'error',
          title: 'There was a problem!',
          description: <Text>{e.response.data?.message?.[0]}</Text>,
        })
        return
      }
      console.log('error!', e)
      setAlert({
        status: 'error',
        title: 'Something went wrong!',
        description: (
          <Text>
            Please contact{' '}
            <Link textDecor={'underline'} href="mailto:support@listener.fm">
              Support
            </Link>
          </Text>
        ),
      })
    }
  }

  return (
    <VStack spacing={8} align="left" width={'full'}>
      <Box pb={8} width={'full'} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl
            id="current_password"
            isInvalid={!!errors.current_password}
          >
            <FormLabel>Current Password</FormLabel>
            <Input type="password" {...register('current_password')} />
            <FormErrorMessage>
              <>{errors.current_password?.message}</>
            </FormErrorMessage>
          </FormControl>
          <FormControl id="password" isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              {...register('password', {
                required:
                  'Password field is required, must be a minimum of 8 characters. ',
                validate: {
                  matchPattern: (v: string) =>
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
                    'Password must contain at least one uppercase letter, one lowercase letter, and one number',
                },
              })}
            />
            <FormErrorMessage>
              <>{errors.password?.message}</>
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="confirm_password"
            isInvalid={!!errors.confirm_password}
          >
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              {...register('confirm_password', {
                required:
                  'Confirm Password field is required, must match password field. ',
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return 'Your passwords do no match'
                  }
                },
              })}
            />
            <FormErrorMessage>
              <>{errors.confirm_password?.message}</>
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={10}>
            <PrimaryButton
              primaryText={isSubmitting ? 'Submitting...' : 'Submit'}
              type="submit"
              isDisabled={false}
              width="100%"
            />
          </Stack>
        </Stack>
      </Box>
    </VStack>
  )
}
