import { Box, Center, Text, Image, VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { User } from '../store/User'
import { Alert as AlertStore, alertState } from '../store/Alert'
import { useRecoilState } from 'recoil'
import { useParams } from 'react-router-dom'
import { EpisodeTable } from '../components/dashboard/EpisodeTable'
import { AuthNavContainer } from '../components/AuthNavContainer'
import { ShowNav } from '../components/dashboard/ShowNav'

export function Dashboard({ success }: { success?: boolean }) {
  const { email } = useParams()

  const [user, podcasts]: [User, any] = useOutletContext()

  const [, setAlert] = useRecoilState<AlertStore>(alertState)

  const { state }: any = useLocation()
  const navigate = useNavigate()
  const welcome = state?.welcome
  useEffect(() => {
    if (success) {
      ;(window as any).fbq('track', 'Purchase')
      setAlert({
        title: 'Thanks for subscribing!.',
        description: 'Upload podcasts, and enjoy!',
        status: 'success',
      })
    }
  }, [success, email, setAlert])
  useEffect(() => {
    if (welcome) {
      ;(window as any).fbq('track', 'CompleteRegistration')
      setAlert({
        title: 'Account created.',
        description: "We've created your account for you.",
        status: 'success',
      })

      navigate('/dashboard', {
        replace: true,
        state: null,
      })
    }
  }, [welcome, navigate])

  useEffect(() => {
    if (!user.slug) {
      return navigate('/onboarding')
    }
  }, [user, navigate])

  return (
    <AuthNavContainer
      user={user}
      NavContent={() => (
        <Box>
          <ShowNav shows={user.shows} />
        </Box>
      )}
    >
      <Center>
        <EpisodeTable user={user} podcasts={podcasts} />
      </Center>
    </AuthNavContainer>
  )
}
