import { useEffect, useRef, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Socket, io } from 'socket.io-client'
import { getPodcastByUuid, getPodcastsForUser, getProfile, Show } from '../api'
import { Alert as AlertStore, alertState } from '../store/Alert'
import { Link } from '@chakra-ui/react'
import { authenticationState, User, userState } from '../store/User'

const COMPLETED_FILE_UUID_REGEX =
  /^complete:([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/

export function usePodcasts(user_id?: number, show_id?: number) {
  if (isNaN(show_id!)) {
    show_id = undefined
  }
  const socket = useRef<Socket | null>(null)
  const is_loading_podcasts = useRef<boolean>(false)
  const [podcasts, setPodcasts] = useState<any[]>([])
  const [{ offset, limit }, setOffsetLimit] = useState({
    offset: 0,
    limit: 100,
  })
  const [, setAlert] = useRecoilState<AlertStore>(alertState)

  useEffect(() => {
    async function load_async() {
      /**@TODO add error handling */
      const result = await getPodcastsForUser(show_id, offset, limit)
      if (!result?.podcasts) {
        return
      }
      setPodcasts((current_podcasts) => {
        return current_podcasts
          .slice(0, offset)
          .concat(result.podcasts.map((x: any) => x))
          .concat(current_podcasts.slice(offset + limit))
      })

      if (result.length) {
        setOffsetLimit({ offset: offset + limit, limit })
      }
    }
    if (is_loading_podcasts.current) {
      return
    }
    is_loading_podcasts.current = true
    load_async()
    is_loading_podcasts.current = false
  }, [limit, offset, show_id])

  useEffect(
    function initializeSocket() {
      socket.current = io(String(process.env.REACT_APP_WS_URL), {
        transports: ['websocket'],
      })

      socket.current.on('message', async (message: string) => {
        const result = message.match(COMPLETED_FILE_UUID_REGEX)
        if (result?.length) {
          const file_uuid = result[1]
          const { podcast: updated_podcast } = await getPodcastByUuid(file_uuid)
          setAlert({
            title: 'Podcast finished!.',
            description: (
              <>
                Thanks for waiting patiently! Your{' '}
                <Link
                  textDecoration={'underline'}
                  href={`podcast/${file_uuid}`}
                >
                  Podcast
                </Link>{' '}
                is ready to view.
              </>
            ),
            status: 'success',
          })
          setPodcasts((podcasts) => {
            return podcasts.map((podcast) =>
              podcast.fileUuid === file_uuid ? updated_podcast : podcast,
            )
          })
        }
      })

      // Clean up the socket connection when the component unmounts
      return () => {
        socket.current?.disconnect()
      }
    },
    [setAlert],
  )

  useEffect(
    function registerFileListeners() {
      if (!socket.current) {
        return
      }

      socket.current.emit('register_client', JSON.stringify([user_id]))
    },
    [user_id],
  )

  return {
    podcasts,
    is_loading_podcasts,
  }
}

export function useShows(user: User) {
  const [shows, setShows] = useState<Show[]>([])
  const is_loading_shows = useRef<boolean>(false)

  useEffect(() => {
    async function load_async() {
      if (!user.shows) {
        return
      }
      /**@TODO add error handling */
      is_loading_shows.current = true
      // const result = await getShows()
      // if (!result?.shows) {
      //   return
      // }
      setShows(user?.shows!)
      is_loading_shows.current = false
    }
    load_async()
  }, [user])
  return { shows, is_loading_shows }
}

export function useUser() {
  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useRecoilState(userState)
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(authenticationState)

  useEffect(() => {
    async function checkLoginStatus() {
      try {
        setLoading(true)
        const profile = await getProfile()
        setUser(profile)
        setIsAuthenticated(true)
      } catch (error) {
        console.error('Error logging in', error)
      } finally {
        setLoading(false)
      }
    }
    if (!Object.keys(user as User).length) {
      checkLoginStatus()
    }
  }, [setIsAuthenticated, setUser, user])
  return { isLoading }
}
