import {
  Text,
  Button,
  Td,
  Tbody,
  Th,
  Thead,
  Tr,
  TableContainer,
  Table,
  Box,
} from '@chakra-ui/react'
import { title } from 'process'
import { useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { getRawOutput } from '../PodcastView'
import { Regenerate } from '../Regenerate'

export function Title({ renderTitle }: { renderTitle: () => JSX.Element }) {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext)

  const titles = getRawOutput(podcast, 'titles')
  if (!titles) {
    return null
  }

  const results = (po: string[]) =>
    po?.map((title: string, idx: number) => (
      <Tr>
        <Td>{idx + 1}</Td>
        <Td width={'xl'}>{title}</Td>
        <Td>
          <CopyToClipboard text={title}>
            <Button variant={'outline'}>
              {' '}
              <FaRegCopy />{' '}
              <Text fontSize={'sm'} p={'2'} pr={0}>
                Copy
              </Text>
            </Button>
          </CopyToClipboard>
        </Td>
      </Tr>
    ))

  const table = titles
    .map((po: string[], generationIdx: number) => (
      <>
        <TableContainer mt={8} w="100%">
          <Table variant="simple">
            <Thead borderTop="1px solid" borderColor="gray.200">
              <Tr>
                <Th>#</Th>
                <Th>Title</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>{results(po)}</Tbody>
          </Table>
        </TableContainer>
      </>
    ))
    .reverse()
  return (
    <Box width="100%">
      <Box mb={8}>{renderTitle()}</Box>
      <Regenerate
        type={'titles'}
        podcast={podcast}
        placeholder="Specify a prompt to regenerate titles"
        buttonText="Regenerate titles"
      />
      {table}
    </Box>
  )
}
