import React, { useCallback, useEffect, useState } from 'react'
import { useForm, SubmitHandler, FieldErrors } from 'react-hook-form'
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Progress,
  Link,
  Center,
  HStack,
} from '@chakra-ui/react'
import { SelectSlug } from './SelectSlug'
import { useRecoilState } from 'recoil'
import { User, userState } from '../../store/User'
import { SearchPodcast } from '../search/SearchPodcast'
import { PodcastResult } from '../search/SearchResults'
import { PodcastInfo } from '../search/PodcastInfo'
import { AddShow } from '../show/AddShow'
import { CreateShow, createShow, Show } from '../../api'
import { Spinner } from '../Spinner'
import { useNavigate } from 'react-router-dom'
import { Alert as AlertStore, alertState } from '../../store/Alert'
import { showState } from '../../store/Show'
import { AxiosError } from 'axios'
import { Logo } from '../Logo'
import { PrimaryButton } from '../shared'

type FormDataStepOne = {
  slug: string
}

export type FormErrorsStepOne = FieldErrors<FormDataStepOne>
const TOTAL_STEPS = 4
export const Onboarding: React.FC = () => {
  const [user, setUser] = useRecoilState<User>(userState)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<any>()
  const navigate = useNavigate()
  const [currentShow, setShow] = useRecoilState<Show>(showState)

  const [manualShow, setManualShow] = useState<boolean | undefined>(undefined)
  const [step, setStep] = useState<number>(1)
  const [slug, setSlug] = useState<string>('')
  const [selectedPodcast, setSelectedPodcast] = useState<PodcastResult>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [, setAlert] = useRecoilState<AlertStore>(alertState)

  useEffect(() => {
    if (user.usage?.show_count! >= user.subscription?.max_shows!) {
      setIsDisabled(true)
      setAlert({
        status: 'error',
        title: 'You need to upgrade to add more shows!',
        description: (
          <Text>
            Please{' '}
            <Link textDecor={'underline'} href="/subscribe">
              subscribe
            </Link>{' '}
            to one of our paid plans.
          </Text>
        ),
      })
      navigate('/dashboard')
    }
  }, [setAlert, user.usage?.show_count, user.subscription?.max_shows])

  const onError = (error: AxiosError) => {
    if (error.response?.status === 403) {
      setAlert({
        status: 'error',
        title: 'You need to upgrade!',
        description: (
          <Text>
            Please{' '}
            <Link textDecor={'underline'} href="/subscribe">
              subscribe
            </Link>{' '}
            to one of our paid plans.
          </Text>
        ),
      })
      return
    }
    console.log('Failed creating show', error)
    setAlert({
      status: 'error',
      title: 'Something went wrong!',
      description: (
        <Text>
          Please contact{' '}
          <Link textDecor={'underline'} href="mailto:support@listener.fm">
            Support
          </Link>
        </Text>
      ),
    })
  }
  const navigateAndSetShow = (show: Show) => {
    setShow(show)
    setUser({} as User)
    navigate(`/dashboard/${show.id}`, {
      replace: true,
      state: null,
    })
  }

  const onSubmitSlug: SubmitHandler<FormDataStepOne> = (data) => {
    setSlug(data.slug)
    setStep(2)
  }

  const onFinalSubmit: SubmitHandler<any> = async () => {
    const show: CreateShow = {
      image: selectedPodcast!.image,
      listenNoteId: selectedPodcast!.id,
      name: selectedPodcast!.title,
    }
    try {
      const data = await createShow(show)
      navigateAndSetShow(data)
      return data
    } catch (error: any) {
      onError(error)
    }
  }

  const goBack = () => {
    setStep((previousStep) => {
      if (previousStep - 1 < 0) {
        return 0
      }
      return previousStep - 1
    })
  }

  const renderStepOne = useCallback(() => {
    if (user.slug) {
      setStep(2)
    }

    return (
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmitSlug)}
        pl={'25px'}
        width={'100%'}
      >
        <Center>
          <SelectSlug />
        </Center>
      </Box>
    )
  }, [handleSubmit, user.slug])

  const renderStepTwo = () => (
    <VStack spacing={6}>
      <Center>
        <Logo image_width="48px" />
      </Center>
      <Box m={'0 auto'}>
        {manualShow ? (
          <AddShow
            onError={onError}
            finishedFunction={navigateAndSetShow}
            isDisabled={isDisabled}
            removeManualShow={() => {
              setManualShow(false)
            }}
          ></AddShow>
        ) : (
          <>
            <SearchPodcast
              setSelectedPodcast={setSelectedPodcast}
              goBack={goBack}
              setStep={setStep}
              isDisabled={isDisabled}
              setManualShow={() => {
                setManualShow(true)
              }}
            />
          </>
        )}
      </Box>
    </VStack>
  )

  const renderStepThree = () => {
    return (
      <Box
        pl={'25px'}
        width={'xl'}
        m={'0 auto'}
        as="form"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <VStack spacing={6}>
          <Center>
            <Logo image_width="48px" />
          </Center>
          <Heading fontSize="xl" pb={0} mb={0}>
            Let's finalize those details!
          </Heading>
          <Text fontSize="sm" color="gray.400">
            Once you submit, you'll be able to upload your first episode.
          </Text>
          {selectedPodcast ? <PodcastInfo podcast={selectedPodcast} /> : null}

          <Button onClick={() => setStep(2)} mt={4} width={'100%'}>
            Back
          </Button>
          <PrimaryButton
            type="submit"
            isDisabled={isSubmitting}
            primaryText="Finish"
          />
        </VStack>
      </Box>
    )
  }

  return (
    <Center h="100%">
      <HStack w="400px" h="100%" pb="40px" align="center">
        {step === 1 && renderStepOne()}
        {step === 2 && renderStepTwo()}
        {step === 3 && renderStepThree()}
      </HStack>
    </Center>
  )
}
