import {
  Text,
  Button,
  Td,
  Tbody,
  Th,
  Thead,
  Tr,
  Table,
  Box,
} from '@chakra-ui/react'
import { useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { getRawOutput } from '../PodcastView'
import { Regenerate } from '../Regenerate'

export function Linkedin({ renderTitle }: { renderTitle: () => JSX.Element }) {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext)

  const linkedin = getRawOutput(podcast, 'linkedin')

  if (!linkedin) {
    return null
  }

  const results = (po: string[]) =>
    po.map((linkedInPost: string, idx: number) => (
      <Tr>
        <Td>{idx + 1}</Td>
        <Td>
          <Text>{linkedInPost}</Text>
        </Td>
        <Td>
          <CopyToClipboard text={linkedInPost}>
            <Button variant={'outline'}>
              {' '}
              <FaRegCopy />{' '}
              <Text fontSize={'sm'} p={'2'} pr={0}>
                Copy
              </Text>
            </Button>
          </CopyToClipboard>
        </Td>
      </Tr>
    ))

  const table = linkedin
    .map((po: string[], generationIdx: number) => (
      <>
        <Thead borderTop="1px solid" borderColor="gray.200">
          <Tr>
            <Th>#</Th>
            <Th>Linkedin Post</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>{results(po)}</Tbody>
      </>
    ))
    .reverse()
  return (
    <>
      <Box mb={8}>{renderTitle()}</Box>
      <Regenerate
        type={'linkedin'}
        podcast={podcast}
        placeholder="Enter prompt for linkedin"
        buttonText="Regenerate Linkedin"
      />
      <Table mt={8}>{table}</Table>
    </>
  )
}
