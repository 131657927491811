import { Flex, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Spinner } from '../components/Spinner'
import { User, userState } from '../store/User'
import { useUser } from '../auth/hooks'

export type PaymentIntent = {
  client_secret: string
  id: string
}

export function Payment() {
  const [user] = useRecoilState<User>(userState)
  const { isLoading } = useUser()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  ;(window as any).fbq('track', 'InitiateCheckout')

  const handleRedirect = useCallback(
    (user: User) => {
      if (!Object.keys(user).length) {
        console.log(user)
        window.alert('register' + isLoading)
        navigate('/register', { state: { subscribe: true } })

        return
      }

      if (user.isActive) {
        window.location.href = `${process.env.REACT_APP_API_URL}/payments/portal`
        return
      }
    },
    [navigate],
  )

  useEffect(() => {
    if (isLoading) {
      return
    }
    ;(window as any).gtag('event', 'conversion', {
      send_to: 'AW-11299564363/CRUPCLWgxNAYEMvWhowq',
      transaction_id: user?.id,
    })
    handleRedirect(user)
  }, [handleRedirect, user, isLoading])

  if ((error as any)?.message) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Text fontSize="4xl">{(error as any).message}</Text>
      </Flex>
    )
  }

  return user.isActive ? (
    <Skeleton>
      <Spinner />
    </Skeleton>
  ) : (
    // @ts-ignore
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
      customer-email={user.email}
      client-reference-id={user.id}
    />
  )
}
