import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Button,
  Heading,
  VStack,
  Text,
  ListItem,
  List,
  HStack,
  Flex,
  ListIcon,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { PodcastInfo } from './PodcastInfo'

export interface PodcastResult {
  id: string
  title: string
  description: string
  publisher: string
  image: string
  total_episodes: number
}

export function SearchResults({
  podcastResults,
  setSelectedPodcast,
  goBack,
  setStep,
}: {
  podcastResults: PodcastResult[]
  setSelectedPodcast: React.SetStateAction<any>
  goBack: () => void
  setStep: React.SetStateAction<any>
}) {
  const selectedId = useRef<string>('')
  const selectPodcast = (podcast: any) => {
    setSelectedPodcast(podcast)
    if (!selectedId.current) {
      return
    }
    setStep(3)
  }
  return (
    <VStack spacing={2} align="left">
      <List width={'fit-content'}>
        {podcastResults.map((podcast) => (
          <ListItem
            key={podcast.id}
            cursor={'pointer'}
            onClick={() => {
              selectedId.current = podcast.id
              selectPodcast(podcast as any)
            }}
          >
            <Flex alignItems={'center'}>
              <PodcastInfo podcast={podcast} _hover={{ boxShadow: 'lg' }} />
            </Flex>
          </ListItem>
        ))}
      </List>
    </VStack>
  )
}
