import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { MdClose } from 'react-icons/md'

export function ContextToast({
  type,
  primaryText,
  actionIcon,
  actionText,
  primaryAction,
}: {
  type?: 'error' | 'success'
  primaryText?: string
  actionIcon?: IconType
  actionText?: string
  primaryAction?: () => void
}) {
  return (
    <Box
      w="90%"
      maxW="650px"
      minH="40px"
      boxShadow="sm"
      position="fixed"
      bottom="50px"
      left="0"
      right="0"
      margin="auto"
      border="1px solid"
      borderRadius="8px"
      borderColor="gray.200"
    >
      <HStack justifyContent="space-between" padding="8px 12px">
        <Text maxW="50%">{primaryText}</Text>
        <HStack>
          {actionText && (
            <Button
              bg="white"
              border="1px solid"
              borderColor="gray.200"
              h="28px"
              p="2px 10px"
              leftIcon={<Icon as={actionIcon} w="20px" />}
              onClick={primaryAction}
              cursor="pointer"
            >
              {actionText}
            </Button>
          )}
          {!actionText && (
            <Icon ml="8px" as={MdClose} cursor="pointer" onClick={() => {}} />
          )}
        </HStack>
      </HStack>
    </Box>
  )
}
