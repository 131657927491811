import { HStack, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export function TopNav({
  items,
  selected_id,
  Content,
  TrailingComponent,
}: {
  items: TopNavItemProp[] | undefined
  selected_id: number | string
  Content: React.ComponentType<any>
  TrailingComponent: React.ComponentType<any>
}) {
  if (!items) {
    return null
  }

  return (
    <HStack
      spacing="12px"
      overflowY="hidden"
      padding="0 20px"
      h="44px"
      __css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      }}
    >
      {items.map((item) => (
        <Link
          to={item.link}
          as={RouterLink}
          style={{ textDecoration: 'none' }}
          _focus={{ boxShadow: 'none' }}
          width={{ md: 'inherit', base: '100%' }}
          key={`nav-link-${item.id}`}
        >
          <HStack
            flexShrink="0"
            boxShadow={selected_id === item.id ? 'md' : undefined}
            bg={selected_id === item.id ? 'white' : undefined}
            padding="6px 12px"
            borderRadius="100px"
            cursor="pointer"
            onClick={() => {}}
            minW="100px"
          >
            <Content item={item.item} />
          </HStack>
        </Link>
      ))}
      <TrailingComponent />
    </HStack>
  )
}

export interface TopNavItemProp {
  link: string
  id: number | string
  item: any
}
