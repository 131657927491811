import { Avatar, Box, Text } from '@chakra-ui/react'
import { ConciseShowNotes } from './outputs/ConciseShowNotes'
import { Linkedin as LinkedInComponent } from './outputs/Linkedin'
import { Twitter as TwitterComponent } from './outputs/Twitter'
import { Quotes as QuoteComponent } from './outputs/Quotes'
import { QuotesJson } from './outputs/QuotesJson'
import { Title } from './outputs/Title'
import { Transcription } from './outputs/Transcription'
import { Summary as SummaryComponent } from './outputs/Summary'
import { Blog } from './outputs/Blog'
import { Podcast } from '../../api/types'
import { useMemo, useCallback } from 'react'
import { PodcastPropContext } from './Context'
import { Chat } from './outputs/Chat'
import { MdClose } from 'react-icons/md'
import { FiAlignLeft, FiLayout, FiLinkedin } from 'react-icons/fi'
import { BsChat, BsFonts } from 'react-icons/bs'
import { FaRegClock, FaRegFileAlt } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

export type ShowNote = {
  start: string
  end: string
  summary: string
  concise_summaries?: string[]
}
export function getRawOutput(podcast: Podcast, type: string) {
  const outputs = podcast.podcastOutputs?.filter((po) => po.name === type)

  if (!outputs) {
    return
  }
  const raw_outputs = outputs.map((po) => po.raw)
  if (!raw_outputs.length) {
    return
  }
  return raw_outputs
}

export function PodcastView({
  podcast,
  setAudioTime,
  tab,
}: {
  podcast?: Podcast
  setAudioTime: any
  tab: string
}) {
  const title = useMemo(
    () =>
      (OUTPUTS_TO_TABS as any)[tab?.toLowerCase()].content(podcast?.fileUuid)
        .item.title,
    [tab, podcast],
  )

  const renderTitle = useCallback(() => {
    return (
      <Text fontSize="32px" fontWeight="bold" pt={0}>
        {title}
      </Text>
    )
  }, [title])

  const page = useMemo(() => {
    switch (tab) {
      case 'transcription':
        return (
          <Transcription
            setAudioTime={setAudioTime}
            renderTitle={renderTitle}
          />
        )
      case 'titles':
        return <Title renderTitle={renderTitle} />
      case 'summaries':
        return <SummaryComponent renderTitle={renderTitle} />
      case 'timestamps':
      case 'show_notes':
        return (
          <ConciseShowNotes
            setAudioTime={setAudioTime}
            renderTitle={renderTitle}
          />
        )
      case 'quotes':
      case 'quotes_json':
        return (
          <>
            <QuotesJson setAudioTime={() => {}} />
            <QuoteComponent />
          </>
        )
      case 'linkedin':
        return <LinkedInComponent renderTitle={renderTitle} />
      case 'twitter':
        return <TwitterComponent renderTitle={renderTitle} />
      case 'blog':
        return <Blog renderTitle={renderTitle} />
      case 'chat':
        return <Chat renderTitle={renderTitle} />
    }
  }, [renderTitle, setAudioTime, tab])

  return (
    <Box w="100%">
      <PodcastPropContext.Provider
        value={{ podcast, setAudioTime: setAudioTime }}
      >
        <Box>{page}</Box>
      </PodcastPropContext.Provider>
    </Box>
  )
}

export const OUTPUTS_TO_TABS = {
  transcription: {
    content: (uuid: string) => ({
      id: 'transcription',
      link: `/podcast/${uuid}/transcription`,
      item: { title: 'Transcription', icon: FiAlignLeft },
    }),
  },
  titles: {
    content: (uuid: string) => ({
      id: 'titles',
      link: `/podcast/${uuid}/titles`,
      item: { title: 'Titles', icon: BsFonts },
    }),
  },
  summaries: {
    content: (uuid: string) => ({
      id: 'summaries',
      link: `/podcast/${uuid}/summaries`,
      item: { title: 'Summaries', icon: FaRegFileAlt },
    }),
  },
  show_notes: {
    content: (uuid: string) => ({
      id: 'timestamps',
      link: `/podcast/${uuid}/timestamps`,
      item: { title: 'Timestamps', icon: FaRegClock },
    }),
  },
  timestamps: {
    content: (uuid: string) => ({
      id: 'timestamps',
      link: `/podcast/${uuid}/timestamps`,
      item: { title: 'Timestamps', icon: FaRegClock },
    }),
  },
  quotes: {
    content: (uuid: string) => ({
      id: 'quotes',
      link: `/podcast/${uuid}/quotes`,
      item: { title: 'Quotes', icon: MdClose },
    }),
    page: [],
  },
  quotes_json: {
    content: (uuid: string) => ({
      id: 'quotes',
      link: `/podcast/${uuid}/quotes`,
      item: { title: 'Quotes', icon: MdClose },
    }),
    page: [<QuotesJson setAudioTime={() => {}} />, <QuoteComponent />],
  },
  linkedin: {
    content: (uuid: string) => ({
      id: 'linkedin',
      link: `/podcast/${uuid}/linkedin`,
      item: { title: 'Linkedin', icon: FiLinkedin },
    }),
  },
  twitter: {
    content: (uuid: string) => ({
      id: 'twitter',
      link: `/podcast/${uuid}/twitter`,
      item: { title: 'X (Twitter)', icon: FaXTwitter },
    }),
  },
  blog: {
    content: (uuid: string) => ({
      id: 'blog',
      link: `/podcast/${uuid}/blog`,
      item: { title: 'Blog', icon: FiLayout },
    }),
  },
  chat: {
    content: (uuid: string) => ({
      id: 'chat',
      link: `/podcast/${uuid}/chat`,
      item: { title: 'Chat with your podcast', icon: BsChat },
    }),
  },
}
