import { Link } from '@chakra-ui/react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from '../api'
import { AuthenticationContext } from '../auth/AuthenticationProvider'

export function Logout({ immediate }: { immediate?: boolean | undefined }) {
  const { reset } = useContext(AuthenticationContext)
  const navigate = useNavigate()
  const logoutAction = async () => {
    await logout()
    reset()
    navigate('/', {})
  }
  if (immediate) {
    logoutAction()
  }
  return <>
    {!immediate && <Link onClick={logoutAction}>Logout</Link>}
  </>
}
