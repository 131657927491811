import { Container, Heading, Link as CLink } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { SidePanel } from '../components/SidePanel'

export function NotFound() {
  return <SidePanel type="notfound">
    <Container width={"fit-content"}>
      <Heading textAlign={"center"} as='h1' size='lg'>Whatever you're looking for is not here. <br /> Try <CLink textDecoration={"underline"}><Link to="/dashboard">Dashboard</Link></CLink> or contact <CLink textDecor={"underline"} href='mailto:support@listener.fm'>Support</CLink>.</Heading>
    </Container>
  </SidePanel>
}
