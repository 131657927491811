import { Button, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { FaXTwitter } from 'react-icons/fa6'
export function RenderTwitterForm({ children }: any) {
  const [isLoading, setLoading] = useState(false)
  const loginWithTwitter = async () => {
    setLoading(true)

    window.location.href = `${process.env.REACT_APP_API_URL}/auth/twitter/login`
  }

  return (
    <VStack spacing={8} align="left">
      <Button
        w="100%"
        isLoading={isLoading}
        onClick={() => loginWithTwitter()}
        background="white"
        borderWidth="1px"
        boxShadow="sm"
        leftIcon={<FaXTwitter />}
      >
        {children}
      </Button>
    </VStack>
  )
}
