import {
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Box,
  InputRightElement,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FaBrain, FaInfoCircle } from 'react-icons/fa'
import { useRecoilState } from 'recoil'
import { regenerate } from '../../api'
import { Podcast } from '../../api/types'
import {
  RegenerateOutput,
  regenerateOutputState,
} from '../../store/RegenerateOutput'
import { Spinner } from '../Spinner'
import { PrimaryButton } from '../shared'
export const getRegenerateType = (type: string) => {
  if (type.toLowerCase() === 'summaries') {
    return 'summary'
  }
  if (type.toLowerCase() === 'blog post') {
    return 'blog'
  }
  return type.toLowerCase()
}
export function Regenerate({
  type,
  podcast,
  placeholder,
  buttonText,
  ...extra
}: {
  type: string
  podcast: Podcast
  showNoteChunks?: number
  placeholder: string
  buttonText: string
}) {
  const [regenerateOutput, setRegenerate] = useRecoilState<RegenerateOutput>(
    regenerateOutputState,
  )

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({})

  const onSubmit = async (data: any) => {
    const regenerate_type = getRegenerateType(type)
    setRegenerate({ type, generating: true })
    await regenerate({
      type: regenerate_type,
      fileUUID: podcast.fileUuid,
      prompt: data.regenerate,
      showNoteChunks: extra.showNoteChunks,
    })
  }

  if (regenerateOutput?.generating) {
    return (
      <Box display={'inline-block'}>
        <Text as="h3" size="sm" mt={'5'}>
          This might take some time, but, feel free to check back in a few.
        </Text>
        <Text as="h3" size="sm" textDecor={'underline'} mt={'5'}>
          Generating...
        </Text>
        <Spinner />
      </Box>
    )
  }
  return (
    <HStack justifyContent="flex-start">
      <InputGroup maxW="400px">
        <InputRightElement children={<FaInfoCircle opacity={'.6'} />} />
        <Input
          type="text"
          placeholder={placeholder}
          {...register('regenerate')}
        />
      </InputGroup>

      <PrimaryButton
        onClick={handleSubmit(onSubmit)}
        isDisabled={isSubmitting}
        variant={'gradient'}
      >
        {buttonText}
      </PrimaryButton>
    </HStack>
  )
}
