import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  fonts: {
    heading: `'Manrope', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  config,
  layerStyles: {
    gradient: {
      backgroundColor: '#f3ec78',
      backgroundSize: '100%',
      WebkitBackgroundClip: 'text !important',
      MozBackgroundClip: 'text',
      fontWeight: '700',
      WebkitTextFillColor: 'transparent',
      MozTextFillColor: 'transparent',
      background: '-webkit-linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%)',
    },
  },
  colors: {
    default: {
      100: '#666D80',
    },
    primary: {
      50: '#E2F8F8',
      100: '#1FC7C7',
      500: '#1FC7C7',
      600: '#199f9f',
    },
  },
  fontSize: '14px',
  components: {
    Link: {
      baseStyle: {
        fontSize: '14px',
      },
    },
    Input: {
      baseStyle: {
        fontSize: '14px',
      },
    },
    Text: {
      baseStyle: {
        fontSize: '14px',
      },
    },
    Button: {
      sizes: {
        md: {
          fontSize: '14px',
        },
      },
      variants: {
        teal: {
          backgroundColor: '#54DECB',
          fontSize: 'sm',
          _hover: {
            textDecoration: 'none',
            backgroundColor: '#25C1AB',
          },
        },
        gradient: {
          background: 'linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%)',
          color: 'white',
          fontSize: 'sm',
          _hover: {
            textDecoration: 'none',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)),linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%)',
            _disabled: {
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)),linear-gradient(135deg, #CB5EEE 0%, #4BE1EC 100%)',
            },
          },
        },
      },
    },
  },
})

export { theme }
