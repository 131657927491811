import {
  Text,
  Button,
  Card,
  CardBody,
  CardHeader,
  Box,
  Table,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { getRawOutput } from '../PodcastView'
import { Regenerate } from '../Regenerate'

export function Blog({ renderTitle }: { renderTitle: () => JSX.Element }) {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext)

  const blog = getRawOutput(podcast, 'blog')

  if (!blog) {
    return null
  }

  const NewDiv = styled.div`
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: revert;
      font-weight: revert;
    }
  `
  const results = (po: string[], id: number) => {
    const html = po.join('<br/>')
    return (
      <>
        <Card mt={8}>
          <CardHeader>
            <CopyToClipboard key={`${id}-blog-copy-clipboard`} text={html}>
              <Button variant={'outline'}>
                {' '}
                <FaRegCopy />{' '}
                <Text fontSize={'sm'} p={'2'} pr={0}>
                  Copy
                </Text>
              </Button>
            </CopyToClipboard>
          </CardHeader>
          <CardBody maxWidth={'4xl'} m={'0 auto'}>
            <NewDiv dangerouslySetInnerHTML={{ __html: html }} />
          </CardBody>
        </Card>
      </>
    )
  }

  const table = blog
    .map((po: string[], generationIdx: number) => (
      <>{results(po, generationIdx)}</>
    ))
    .reverse()
  return (
    <>
      <Box mb={8}>{renderTitle()}</Box>
      <Regenerate
        type={'blog'}
        podcast={podcast}
        placeholder="Enter prompt for blog"
        buttonText="Regenerate Blog Posts"
      />
      {table}
    </>
  )
}
