import { ChevronDownIcon, EditIcon } from '@chakra-ui/icons'
import {
  Text,
  Button,
  Td,
  Tbody,
  Th,
  Link,
  Thead,
  Tr,
  TableContainer,
  Table,
  TableCaption,
  useDisclosure,
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  Flex,
  HStack,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { getPodcastTranscriptionFormatted } from '../../../api'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { EditSpeakerNames } from '../modal/EditSpeakerNames'
import { getRawOutput } from '../PodcastView'
import { saveAs } from 'file-saver'

type Transcript = {
  speaker: number
  startTime: string
  transcript: string
}

export function Transcription({
  setAudioTime,
  renderTitle,
}: {
  setAudioTime: any
  renderTitle: () => JSX.Element
}) {
  const { podcast } = useContext<{
    podcast: Podcast<Transcript>
  }>(PodcastPropContext)
  const host_names = podcast.hostNames ? podcast.hostNames.split(',') : []
  const guest_names = podcast.guestNames ? podcast.guestNames.split(',') : []
  const [names, setNames] = useState<string[]>([...host_names, ...guest_names])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const download = async (type: string) => {
    const { data } = await getPodcastTranscriptionFormatted(
      podcast.fileUuid,
      type,
    )
    const filename = `${podcast.name.replace(/[/\\ ?%*:|',"<>]/g, '-')}.${type}`
    return saveAs(data, filename)
  }
  const transcription = getRawOutput(podcast, 'transcription')?.[0]

  if (!transcription) {
    return null
  }

  const getNumberToSpeaker = (speaker_number: number) => {
    const speaker_name = names[speaker_number - 1]
    if (!speaker_name) {
      return speaker_number
    }

    return speaker_name.trim()
  }

  const transcription_to_copy = transcription
    .map(
      (transcript) =>
        `Speaker ${getNumberToSpeaker(transcript.speaker)}: ${
          transcript.transcript
        }`,
    )
    .join('\n')

  const transcription_to_table = transcription.map((transcript) => (
    <Tr>
      <Td>
        <Link onClick={setAudioTime(transcript.startTime)}>
          {transcript.startTime}
        </Link>
      </Td>
      <Td>{getNumberToSpeaker(transcript.speaker)}</Td>
      <Td>{transcript.transcript}</Td>
    </Tr>
  ))

  return (
    <>
      <HStack w="100%" justifyContent="space-between">
        {renderTitle?.()}
        <Flex flexDirection={'row-reverse'}>
          <CopyToClipboard text={transcription_to_copy}>
            <Button variant={'outline'} mx={4}>
              {' '}
              <FaRegCopy />{' '}
              <Text fontSize={'sm'} p={'2'} pr={0}>
                Copy
              </Text>
            </Button>
          </CopyToClipboard>
          <Menu>
            <MenuButton
              as={Button}
              mx={4}
              rightIcon={<ChevronDownIcon />}
              variant={'outline'}
            >
              Download
            </MenuButton>
            <MenuList>
              <MenuItem onClick={(e) => download('srt')}>as SRT</MenuItem>
              <MenuItem onClick={(e) => download('vtt')}>
                as VTT/WEBVTT
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <TableContainer key={`${podcast.fileUuid}-inner-transcription`}>
        <Table
          variant="simple"
          layout={'fixed'}
          width={'100%'}
          whiteSpace={'pre-wrap'}
        >
          <TableCaption placement="top" p={0} mb={8}></TableCaption>
          <Thead borderTop="1px solid" borderColor="gray.200">
            <Tr>
              <Th width={'15%'}>Time</Th>
              <Th width={'15%'}>
                Speaker{' '}
                <Button
                  variant={'ghost'}
                  size={'sm'}
                  onClick={() => {
                    onOpen()
                  }}
                >
                  <EditIcon></EditIcon>
                </Button>
              </Th>
              <Th>Transcription</Th>
            </Tr>
          </Thead>
          <Tbody>{transcription_to_table}</Tbody>
        </Table>
        <EditSpeakerNames
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          names={names}
          setNames={setNames}
        />
      </TableContainer>
    </>
  )
}
