import {
  HStack,
  Icon,
  Center,
  Heading,
  Image,
  Box,
  Text,
  Skeleton,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Show, getPodcastByUuid } from '../api'
import { OUTPUTS_TO_TABS, PodcastView } from '../components/podcast/PodcastView'
import { podcastState } from '../store/Podcast'
import { User } from '../store/User'
import { Podcast } from '../api/types'
import { TopNav } from '../components/shared/TopNav'
import { AuthNavContainer } from '../components/AuthNavContainer'
import { MdClose } from 'react-icons/md'
import { Spinner } from '../components/Spinner'
import { showState } from '../store/Show'
import Audio from '../components/podcast/Audio'

enum STATUSES {
  'SUCCEEDED' = 'succeeded',
  'STARTED' = 'started',
  'FAILEd' = 'failed',
}

const output_types = Object.keys(OUTPUTS_TO_TABS)

export function PodcastPage() {
  const { uuid, tab: selected_tab } = useParams()
  const tab = selected_tab ?? 'transcription'
  const [user, podcasts]: [User, any] = useOutletContext()
  const [currentShow] = useRecoilState<Show>(showState)

  const [audioTime, changeAudioTime] = useState('00:00:00')
  const [isLoaded, setLoaded] = useState(false)
  const [podcast, setPodcast] = useRecoilState<Podcast>(podcastState)
  const [error, setError] = useState(null)
  const setAudioTime = (data: string) => () => {
    changeAudioTime(data)
  }
  const getPodcast = async (uuid: string) => {
    try {
      const { podcast } = await getPodcastByUuid(uuid)
      const formatted_podcast = podcast
      setPodcast(formatted_podcast)
    } catch (e: any) {
      setError(e)
      setLoaded(true)
    }
  }
  useEffect(() => {
    if (uuid) {
      setLoaded(false)
      getPodcast(uuid)
      setLoaded(true)
    }
  }, [uuid, podcasts])

  const PodcastViewMemo = useMemo(
    () => (
      <PodcastView tab={tab} podcast={podcast} setAudioTime={setAudioTime} />
    ),
    [podcast, tab],
  )

  const outputs = useMemo(
    () => new Set<string>(podcast?.podcastOutputs?.map((po: any) => po?.name)),
    [podcast],
  )

  if (outputs.size !== 0) {
    outputs.add('chat')
  }

  const items = output_types
    .map((x: string) => {
      if (outputs.has(x)) {
        return (OUTPUTS_TO_TABS as any)[x].content(uuid)
      }

      return
    })
    .filter((x) => x)

  const navOptions = useCallback(
    () => (
      <TopNav
        selected_id={tab?.toLowerCase() ?? ''}
        items={items}
        Content={({ item }) => (
          <>
            <Icon ml="8px" as={item.icon} cursor="pointer" />
            <Text maxH="32px" fontSize="14px">
              {item.title}
            </Text>
          </>
        )}
        TrailingComponent={() => <></>}
      />
    ),
    [items, tab],
  )

  return (
    <AuthNavContainer user={user} NavContent={navOptions} showDashboardNav>
      <Box p={14}>
        {!podcast ? (
          <Spinner />
        ) : podcast?.status === STATUSES.SUCCEEDED ? (
          <>
            <VStack align={'flex-start'}>
              <Text color="default.100">
                {currentShow?.name}
                {'  >  '}
                {podcast?.name}
              </Text>
              {PodcastViewMemo}
              <Box
                height="64px"
                width="50%"
                position="fixed"
                border="1px solid"
                borderColor="gray.200"
                p="8px"
                bottom="20px"
                bg="white"
                boxShadow="lg"
                borderRadius="8px"
                left="0"
                right="0"
                margin="auto"
              >
                <HStack>
                  <HStack width="40%">
                    <Image width="48px" height="48px" src={'/logo.png'} />
                    <VStack pl="4px" align="flex-start" spacing={0}>
                      <Text fontSize="14px" color="gray.500">
                        {currentShow?.name}
                      </Text>
                      <Text fontSize="16px">{podcast?.name}</Text>
                    </VStack>
                  </HStack>
                  {podcast?.presigned_url && (
                    <Box width="60%">
                      <Audio
                        src={podcast.presigned_url}
                        audioTime={audioTime}
                      />
                    </Box>
                  )}
                </HStack>
              </Box>
            </VStack>
          </>
        ) : (
          <Center>
            <Heading as="h1" size="md">
              This page will automatically refresh once we're done generating
              the content. Feel free to come back or upload another episode!
            </Heading>
          </Center>
        )}
      </Box>
    </AuthNavContainer>
  )
}
