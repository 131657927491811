import { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Spinner } from '../components/Spinner'
import { AuthenticationContext } from './AuthenticationProvider'

const UnauthenticatedRoute = () => {
  const { isAuthenticated, isLoading } = useContext(AuthenticationContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard')
    }
  }, [isAuthenticated, isLoading, navigate])
  if (isAuthenticated) {
    return <Spinner />
  }
  return <Outlet />
}

export { UnauthenticatedRoute }
