import { Image, Box, Center, VStack, Text, HStack } from '@chakra-ui/react'

export const DUMMY_TESTIMONIAL_ID = Symbol('DUMMY')
export const APPSUMO_TESTIMONIAL_ID = Symbol('APPSUMO')
export const JEFF = Symbol('JEFF')
export const DAN = Symbol('DAN')

const TESTIMONIALS = {
  [DUMMY_TESTIMONIAL_ID]: {
    content: `“Listener.fm allowed me to step up my podcast post-production in a
        seamless way. I no longer have to generate titles, descriptions,
        timestamps, and other details. It does it all in a matter of a few
        seconds. I’m amazed.”`,
    image_path: '/logo.png',
    author: 'Tom Smith',
    podcast_name: 'Podcast Name',
  },
  [APPSUMO_TESTIMONIAL_ID]: {
    content: `Hello Sumoling, and welcome to Listener.fm! Dive into a world where AI enhances your podcast's post-production, delivering captivating titles and descriptions swiftly. Thank you for choosing us to elevate your podcasting experience!`,
    image_path: '/logo.png',
    author: 'Casey Adams',
    podcast_name: 'Founder',
  },
  [DAN]: {
    content: `The Money Mondays relies on Listener.fm for its crisp, clear, and informative content descriptions. The AI's ability to produce finance-focused blog posts and notes saves us countless hours.`,
    image_path: '/dan.jpg',
    author: 'Dan Fleyshman',
    podcast_name: 'The Money Mondays',
  },
  [JEFF]: {
    content: `Listener.fm has transformed my post-production workflow. The AI effortlessly crafts catchy titles and compelling descriptions, allowing me to focus more on creating great content for The Jeff Fenster Show.`,
    image_path: '/jeff.jpg',
    author: 'Jeff Fenster',
    podcast_name: 'The Jeff Fenster Show',
  },
}

type PossibleTestimonials =
  | typeof DUMMY_TESTIMONIAL_ID
  | typeof DAN
  | typeof JEFF
  | typeof APPSUMO_TESTIMONIAL_ID

let default_testimonial: PossibleTestimonials = DAN
const random = Math.random()
if (random * 2 < 1) {
  default_testimonial = JEFF
}

export function TestimonialCard(props: {
  testimonial_id?: PossibleTestimonials
}) {
  const testimonial_id: PossibleTestimonials =
    props.testimonial_id ?? default_testimonial
  const testimonial = TESTIMONIALS[testimonial_id]
  return (
    <Box
      w="340px"
      bg="white"
      borderRadius="6px"
      padding="40px"
      boxShadow="md"
      borderBottomColor="#1BB1B1"
      borderBottomWidth="3px"
    >
      <VStack spacing={20}>
        <Center>
          <Text>{testimonial.content}</Text>
        </Center>
        <Center w="100%">
          <HStack w="100%" spacing={4}>
            <Image
              w="40px"
              h="40px"
              borderRadius="50%"
              src={testimonial.image_path}
            />
            <VStack alignItems="flex-start">
              <Text align="left">{testimonial.author}</Text>
              <Text align="left">{testimonial.podcast_name}</Text>
            </VStack>
          </HStack>
        </Center>
      </VStack>
    </Box>
  )
}
