import {
  Box,
  Text,
  Center,
  Flex,
  Image,
  Link,
  Stack,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Button,
  FormHelperText,
  HStack,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Upload as UploadComponent } from '../components/Upload'
import { Alert as AlertStore, alertState } from '../store/Alert'
import { User, userState } from '../store/User'
import { AddIcon } from '@chakra-ui/icons'
import { Uploader } from '../utilities/Uploader'
import { useForm } from 'react-hook-form'
import { uploaderState } from '../store/Uploader'
import { FiMic, FiUser } from 'react-icons/fi'
import { AxiosError } from 'axios'
import { useCallback, useEffect } from 'react'
import { UploadCanceled } from '../errors/UploadCanceled'
import { FileSizeLimit } from '../errors/FileSizeLimit'
import { Show } from '../api/ShowApi'
import { showState } from '../store/Show'
import { AuthNavContainer } from '../components/AuthNavContainer'
import { BsInfoCircleFill } from 'react-icons/bs'
import { PrimaryButton } from '../components/shared'

export function Upload() {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState<User>(userState)
  const [, setAlert] = useRecoilState<AlertStore>(alertState)
  const [currentShow] = useRecoilState<Show>(showState)

  const [uploader] = useRecoilState<Uploader>(uploaderState)
  const defaultValues: {
    episodeName: null | string
    hostNames: null | string | string[]
    guestNames: null | string | string[]
    showId: null | number
  } = {
    episodeName: null,
    hostNames: null,
    guestNames: null,
    showId: currentShow?.id || null,
  }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ defaultValues })
  useEffect(() => {
    setValue('showId', currentShow?.id)
  }, [currentShow, setValue])
  const onSubmit = useCallback(
    (data: any) => {
      if (!uploader?.file) {
        return
      }
      uploader.start(data)
    },
    [uploader],
  )

  const onComplete = (data: any) => {
    if (data.podcast) {
      setUser({})
      navigate(`/dashboard/${currentShow?.id || 'default'}`)
    }
  }
  const onError = (error: AxiosError) => {
    if (error.response?.status === 403) {
      setAlert({
        status: 'error',
        title: 'You need to upgrade!',
        description: (
          <Text>
            Please{' '}
            <Link textDecor={'underline'} href="/subscribe">
              subscribe
            </Link>{' '}
            to one of our paid plans.
          </Text>
        ),
      })
      return
    }
    if (error instanceof UploadCanceled) {
      return
    }
    if (error instanceof FileSizeLimit) {
      setAlert({
        status: 'error',
        title: 'File size is too large!',
        description: (
          <Text>
            We only support files smaller than 800mb. <br /> Please contact
            support if you have any issues.
          </Text>
        ),
      })
      return
    }
    console.log('Failed uploading', error)
    setAlert({
      status: 'error',
      title: 'Something went wrong!',
      description: (
        <Text>
          Please contact{' '}
          <Link textDecor={'underline'} href="mailto:support@listener.fm">
            Support
          </Link>
        </Text>
      ),
    })
  }
  return (
    <AuthNavContainer user={user} NavContent={() => <></>} showDashboardNav>
      <Center>
        <VStack alignItems={'center'} mt="60px" mb={4} w={'400px'}>
          <Text fontSize="24px" fontWeight="bold">
            Upload your new episode
          </Text>

          <UploadComponent onCompleteFn={onComplete} onErrorFn={onError} />
          <Text
            bg="gray.100"
            w="100%"
            borderRadius="100px"
            padding="2px 8px"
            align="center"
            mt="40px"
            mb="20px"
          >
            Optional information - it will give you better results
          </Text>
          <FormControl mb="12px">
            <FormLabel fontSize="14px">Episode Name</FormLabel>
            <InputGroup>
              <InputLeftElement children={<FiMic />} />
              <Input
                fontSize="14px"
                type="text"
                placeholder={uploader?.file?.name || 'Episode Name'}
                {...register('episodeName')}
              />
            </InputGroup>
          </FormControl>
          <FormControl mb="12px">
            <FormLabel fontSize="14px">Host Name(s)</FormLabel>
            <InputGroup>
              {/* <AutoComplete multiple creatable submitKeys={[',']}>
                <AutoCompleteInput variant={"flushed"} {...register('hostNames')} placeholder="Host name's separated by comma or return key.">
                  {({ tags }) =>
                    tags.map((tag, tid) => (
                      <AutoCompleteTag
                        key={tid}
                        label={tag.label}
                        onRemove={tag.onRemove}
                      />
                    ))
                  }
                </AutoCompleteInput>
              </AutoComplete> */}

              <InputLeftElement children={<FiUser />} />
              <Input
                fontSize="14px"
                type="text"
                placeholder="Brandon Max, Casey Adams"
                {...register('hostNames')}
              />
            </InputGroup>
            <FormHelperText fontSize="12px">Separated by comma</FormHelperText>
          </FormControl>
          <FormControl mb="12px">
            <FormLabel fontSize="14px">Guest Name(s)</FormLabel>
            <InputGroup>
              {/* <AutoComplete multiple creatable submitKeys={[',']}>
                <AutoCompleteInput variant={"flushed"} {...register('guestNames')}
                placeholder="Guest name's separated by comma or return key.">
                {({ tags }) =>
                tags.map((tag, tid) => (
                  <AutoCompleteTag
                  key={tid}
                  label={tag.label}
                  onRemove={tag.onRemove}
                  />
                  ))
                }
                </AutoCompleteInput>
              </AutoComplete> */}
              <InputLeftElement children={<FiUser />} />
              <Input
                fontSize="14px"
                type="text"
                {...register('guestNames')}
                placeholder="Brandon Max, Casey Adams"
              />
            </InputGroup>
            <FormHelperText fontSize="12px">Separated by comma</FormHelperText>
          </FormControl>

          <Input
            type="hidden"
            {...register('showId', {
              valueAsNumber: true,
            })}
          />
          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            variant={'teal'}
            mr={4}
            isDisabled={isSubmitting || uploader?.inProgress}
            w="100%"
          >
            Continue
          </PrimaryButton>
          <Text fontSize="sm" color="gray.400" w="100%">
            This episode will be uploaded to <b>{currentShow?.name}</b>
          </Text>
        </VStack>
      </Center>
    </AuthNavContainer>
  )
}
