import { axios_instance } from './BaseApi'

export type Show = {
  id: number
  name: string
  image: string
}

export type CreateShow = {
  listenNoteId: string
  name: string
  image: string
}
export const getShows = async () => {
  const { data } = await axios_instance.get(`shows`)

  return data
}
export const createShow = async (show: CreateShow) => {
  const { data } = await axios_instance.post(`shows`, show)

  return data
}
export const createManualShow = async (show: FormData) => {
  const { data } = await axios_instance.post(`shows/manual`, show)

  return data
}
