import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { loginWithFacebookCode, loginWithGoogleCode, loginWithTwitterCode } from '../api'
import { Spinner } from '../components/Spinner'
const executeLoginAPI = async (login_type: string, query: URLSearchParams) => {
  const type = login_type.toLowerCase()
  if (type === 'facebook') {
    return loginWithFacebookCode(query.get('code') || '')
  }
  if (type === 'twitter') {
    return loginWithTwitterCode(query.toString())
  }
  if (type === 'google') {
    return loginWithGoogleCode(query.toString())
  }
}
const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export const HandleCallback = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const { type } = useParams()

  useEffect(() => {
    async function loginEffect() {
      if (!type) {
        return
      }

      try {
        const { user } = await executeLoginAPI(type, query)

        if (user.slug) {
          return navigate('/dashboard')
        }

        navigate('/onboarding', { replace: true })
      } catch (error) {
        navigate('/')
      }
    }

    loginEffect()
  }, [type, navigate, query])
  return (
    <>
      <Spinner />
    </>
  )
}
