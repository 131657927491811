import { Box, Grid, Heading, Text, Image, HStack } from '@chakra-ui/react'
import { CSSProperties, useMemo } from 'react'
import { PodcastResult } from './SearchResults'

interface PodcastInfoProps {
  podcast: PodcastResult
  _hover?: CSSProperties
}
const regex = /(<([^>]+)>)/gi

export const PodcastInfo: React.FC<PodcastInfoProps> = ({ podcast }) => {
  const description = useMemo(() => {
    if (podcast?.description.length < 100) {
      return podcast.description
    }

    return podcast?.description.slice(0, 100) + '...'
  }, [podcast])

  if (!podcast) {
    return null
  }

  return (
    <HStack
      align="flex-start"
      padding={4}
      w="100%"
      borderBottom="1px solid"
      borderColor="gray.100"
      _hover={{
        bg: 'gray.50',
      }}
    >
      <Image
        mt="5px"
        borderRadius="8px"
        w="36px"
        h="36px"
        src={podcast.image}
        alt={`Cover art for ${podcast.title}`}
      />
      <Box pl="4px">
        <Text size="sm">{podcast.title}</Text>
        <Text fontSize="sm" color="gray.500">
          {podcast.publisher}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {podcast.total_episodes} episodes
        </Text>
        <Text fontSize="sm" mt={1}>
          {description.replace(regex, '')}
        </Text>
      </Box>
    </HStack>
  )
}
