import { createContext } from 'react'
import { Outlet } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Spinner } from '../components/Spinner'
import { authenticationState, User, userState } from '../store/User'
import { useUser } from './hooks'

const AuthenticationContext = createContext({
  user: {} as User,
  isAuthenticated: false,
  isLoading: false,
  reset: () => {},
})

function AuthenticationProvider({ children }: any) {
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(authenticationState)
  const [user, setUser] = useRecoilState(userState)
  const { isLoading } = useUser()
  const reset = () => {
    setUser({} as User)
    setIsAuthenticated(false)
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        reset,
      }}
    >
      {!isLoading && <Outlet />}
      {isLoading && <Spinner />}
    </AuthenticationContext.Provider>
  )
}

export { AuthenticationProvider, AuthenticationContext }
