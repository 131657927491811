import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
type ProfileViewInterface = {
  image: string
  fullName: string
  firstName: string
  lastName: string
  slug: string
  buttons: any[]
}

export default function SocialProfile({
  image,
  fullName,
  firstName,
  lastName,
  slug,
  buttons,
}: ProfileViewInterface) {
  return (
    <Center py={6}>
      <Box
        maxW={'520px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}
      >
        <Avatar
          size={'xl'}
          src={image}
          name={fullName}
          referrerPolicy="no-referrer"
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: 'green.300',
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {firstName} {lastName}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          @{slug}
        </Text>

        <VStack mt={8} direction={'row'} spacing={4}>
          {buttons}
        </VStack>
      </Box>
    </Center>
  )
}
