import {
  Box,
  Button,
  Checkbox,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Td,
  Image,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Tbody,
  Icon,
  VStack,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../shared'
import { Usage } from '../Usage'
import { FaCheck, FaRegTrashAlt } from 'react-icons/fa'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { ContextToast } from './ContextToast'

export function EpisodeTable({ user, podcasts }: { user: any; podcasts: any }) {
  const [selectAll, setSelectAll] = useState(false)
  const [selectedIds, setSelectedIds] = useState<{ [id: string]: boolean }>({})

  useEffect(() => {
    if (!selectAll) {
      setSelectedIds({})
    } else {
      const ids: any = {}
      for (let i = 0; i < podcasts.length; i++) {
        ids[i + 1] = true
      }

      setSelectedIds(ids)
    }
  }, [podcasts, selectAll])

  const selectedKeys = useMemo(
    () => Object.keys(selectedIds).filter((id: string) => selectedIds[id]),
    [selectedIds],
  )

  /** @TODO uncomment when episode removal has been implemented. */
  const removeEpisodes = useCallback(() => {}, [selectedIds])

  return (
    <Box w="100%" h="100%" p="20px">
      <EpisodeHeader user={user} />
      {!podcasts.length ? (
        <VStack justifyContent="center" height="lg">
          <Image width="126px" src="/ghost.png" />
          <Text fontSize="24px" fontWeight="bold">
            You haven't uploaded an episode yet.
          </Text>
          <Text color="gray.500">
            Click the “New episode” button to add your first episode
          </Text>
        </VStack>
      ) : (
        <TableContainer>
          <Table
            variant="simple"
            borderTop="1px solid"
            borderColor="gray.200"
            marginTop="40px"
          >
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    paddingRight="8px"
                    colorScheme="primary"
                    isChecked={
                      selectedKeys.length === podcasts.length ? true : false
                    }
                    onChange={() => setSelectAll((x) => !x)}
                  />
                  Number
                </Th>
                <Th>Name</Th>
                <Th>Status</Th>
                <Th>Upload date</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {podcasts?.map((podcast: any, idx: number) => (
                <Tr key={podcast.fileUuid}>
                  <Td>
                    <Checkbox
                      colorScheme="primary"
                      marginTop="2px"
                      isChecked={selectedIds[idx + 1] ?? false}
                      onChange={(event) =>
                        setSelectedIds((ids) => {
                          ids[idx + 1] = !ids[idx + 1]
                          console.log(ids)
                          return { ...ids }
                        })
                      }
                    />
                    <Link
                      style={{ marginLeft: '8px' }}
                      to={`/podcast/${podcast.fileUuid}`}
                    >
                      {idx + 1}
                    </Link>
                  </Td>
                  <Td>
                    <Link to={`/podcast/${podcast.fileUuid}`}>
                      {podcast.name}
                    </Link>
                  </Td>
                  <Td>
                    {podcast.status === 'succeeded' ? (
                      <FaCheck />
                    ) : (
                      <Tooltip label="Podcast is generating. Expect an email you or you can wait on this page!">
                        <Spinner />
                      </Tooltip>
                    )}
                  </Td>
                  <Td>
                    {Intl.DateTimeFormat('en-us', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    }).format(new Date(podcast.createdAt))}
                  </Td>
                  <Td>
                    <Link to={`/podcast/${podcast.fileUuid}`}>
                      <HStack>
                        <Text fontWeight="bold">{'View episode '}</Text>
                        <Icon as={MdOutlineArrowForwardIos} />
                      </HStack>
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {selectedKeys.length ? (
        <ContextToast
          primaryText={`${selectedKeys.length} episodes selected`}
          actionIcon={FaRegTrashAlt}
          actionText="Remove"
          primaryAction={removeEpisodes}
        />
      ) : null}
    </Box>
  )
}

function EpisodeHeader({ user }: { user: any }) {
  const navigate = useNavigate()
  const new_episode = useCallback(() => {
    navigate('/upload')
  }, [])

  return (
    <HStack justifyContent="space-between">
      <HStack>
        <Text fontSize="24px" fontWeight="bold">
          Your Episodes
        </Text>
        <Usage user={user} />
      </HStack>
      <PrimaryButton
        primaryText="+ New episode"
        onClick={new_episode}
        isDisabled={false}
        width="130px"
      />
    </HStack>
  )
}
