import { useForm } from 'react-hook-form'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Text,
  Link,
  Heading,
  Image,
  InputGroup,
  InputLeftElement,
  VStack,
} from '@chakra-ui/react'
import { FaImage } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { showState } from '../../store/Show'
import { createManualShow, Show } from '../../api'
import { unstable_useBlocker } from 'react-router-dom'
import { Alert as AlertStore, alertState } from '../../store/Alert'
import { Spinner } from '../Spinner'
import { AxiosError } from 'axios'
import { PrimaryButton } from '../shared'

interface FormData {
  name: string
  image: FileList
}

export function AddShow({
  finishedFunction,
  onError,
  removeManualShow,
  isDisabled,
}: {
  finishedFunction: (data: Show) => void
  onError: (error: AxiosError) => void
  removeManualShow: () => void
  isDisabled: boolean
}) {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [image, name] = watch(['image', 'name'])
  const [, setAlert] = useRecoilState<AlertStore>(alertState)

  const { ref, ...rest } = register('image', { required: true })
  const [filePreview, setFilePreview] = useState<string | undefined>()
  const [blocked, setBlocked] = useState<boolean>(false)
  const [currentShow, setShow] = useRecoilState<Show>(showState)
  unstable_useBlocker(() => {
    if (blocked) {
      setAlert({
        title: 'You must click submit to add the show!',
        description: 'Going back will not save any changes.',
        status: 'warning',
      })
      setBlocked(false)
    }
    return blocked
  })
  useEffect(() => {
    if (image?.length && name) {
      const image_url = URL.createObjectURL(image[0])
      setFilePreview(image_url)
      setShow({ id: 0, name, image: image_url! })
      setBlocked(true)
    }
  }, [image, name])

  const onSubmit = async (data: FormData) => {
    const formData = new FormData()
    formData.append('image', data.image[0])
    formData.append('name', data.name)
    setBlocked(false)
    try {
      const show = await createManualShow(formData)
      finishedFunction(show)
      return show
    } catch (error: any) {
      onError(error)
    }
  }

  console.log(
    image,
    name,
    image?.length > 0 && name?.length > 0,
    image?.length > 0,
    name?.length > 0,
  )
  return isSubmitting ? (
    <Spinner />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} align="center">
        <Heading fontSize="xl" pb={0} mb={0}>
          Let's find your podcast!
        </Heading>
        <Text mt="4" fontSize="sm" color="gray.400" align="center">
          You can manually add your podcast here. Are you listed on streaming
          platforms?{' '}
          <Link fontWeight="bold" onClick={removeManualShow} cursor={'pointer'}>
            Find your show here
          </Link>
        </Text>
        <FormControl isInvalid={!!errors.name}>
          <FormLabel htmlFor="name">Podcast Name</FormLabel>
          <Input
            type="text"
            id="name"
            {...register('name', { required: true })}
          />
          <FormErrorMessage>Name is required</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.image}>
          <FormLabel htmlFor="image">Thumbnail</FormLabel>
          <InputGroup>
            {!filePreview && (
              <InputLeftElement pointerEvents="none">
                <Icon as={FaImage} />
              </InputLeftElement>
            )}
            {filePreview && (
              <InputLeftElement pointerEvents="none">
                <Image
                  boxSize="34px"
                  borderRadius={'30px'}
                  p={1}
                  mr={2}
                  objectFit="cover"
                  src={filePreview}
                  alt={'Temp image'}
                />{' '}
              </InputLeftElement>
            )}
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={(e) => {
                ref(e)
                inputRef.current = e
              }}
              {...rest}
            />
            <Input
              style={{ cursor: 'pointer' }}
              placeholder={
                filePreview
                  ? 'You may click here to change this image ...'
                  : 'Upload a thumbnail'
              }
              readOnly={true}
              onClick={() => inputRef.current!.click()}
              _placeholder={{ width: '100%', textAlign: 'center' }}
            />
          </InputGroup>
          <FormErrorMessage>Image is required</FormErrorMessage>
        </FormControl>

        <PrimaryButton
          primaryText="Continue"
          type="submit"
          isDisabled={image?.length === 0 || name?.length === 0}
        />
      </VStack>
    </form>
  )
}
