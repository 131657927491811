import { Container, useToast } from '@chakra-ui/react'
import { useContext, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Show } from '../api'
import Sidebar from '../components/sidebar/Sidebar'
import { Spinner } from '../components/Spinner'
import { showState } from '../store/Show'
import { AuthenticationContext } from './AuthenticationProvider'
import { usePodcasts, useShows } from './hooks'

const AuthenticatedRoute = () => {
  const { isAuthenticated, user, isLoading } = useContext(AuthenticationContext)
  const { shows } = useShows(user)
  const navigate = useNavigate()
  const { show_id } = useParams()
  const [show, setShow] = useRecoilState<Show>(showState)
  useEffect(() => {
    if (!show || show_id) {
      const copy_of_shows = [...shows]
      const new_show = copy_of_shows.find((show) => show.id === Number(show_id))
      setShow(new_show || copy_of_shows[0])
    }
  }, [show, show_id, shows])
  const { podcasts } = usePodcasts(user.id, Number(show_id))

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    }
    if (isAuthenticated && user) {
      ;(window as any).tidioChatApi?.setVisitorData({
        distinct_id: user.id,
        email: user.email,
      })
      ;(window as any).heap.identify(user.id)
    }
  }, [isAuthenticated, user, isLoading, navigate])
  useEffect(() => {
    if (
      isAuthenticated &&
      Object.keys(user).length &&
      user.isVerified === false
    ) {
      navigate('/verify')
    }
  }, [isAuthenticated, user, user.isVerified])
  if (!isAuthenticated) {
    return <Spinner />
  }
  return (
    <Container w={'100%'} maxW={'100%'} p={0}>
      {isLoading && <Spinner />}
      {!isLoading && <Outlet context={[user, podcasts]} />}
    </Container>
  )
}

export { AuthenticatedRoute }
