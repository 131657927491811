import { Box, Text } from '@chakra-ui/react'
import { User } from '../store/User'
import { useMemo } from 'react'

export function Usage({ user }: { user: User }) {
  const max_podcasts = useMemo(() => {
    if (user.subscription) {
      return user.subscription.max_podcasts + ' usage limit'
    }

    if (user.billingState === 'appSumo') {
      return 'Unlimited'
    }
  }, [user])
  return (
    <Box bg="primary.50" padding="2px 8px" borderRadius="100px">
      <Text color="primary.100">
        {user.usage?.monthly_podcast_count}/{max_podcasts}
      </Text>
    </Box>
  )
}
