import React, { useState, SyntheticEvent, useEffect } from 'react'
import {
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Flex,
  Center,
  HStack,
} from '@chakra-ui/react'
import {
  FaPlayCircle,
  FaPauseCircle,
  FaVolumeMute,
  FaVolumeUp,
  FaSpinner,
} from 'react-icons/fa'

import { useRef } from 'react'
import { CiPause1, CiPlay1 } from 'react-icons/ci'
import { MdOutlinePlayArrow } from 'react-icons/md'

const Audio = ({ src, audioTime }: { src: string; audioTime: string }) => {
  const [playing, setPlaying] = useState(false)
  const [loading, setLoading] = useState(false)
  const [muted, setMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)

  const minutesToSeconds = (value: string) => {
    const [hours, minutes, seconds] = value.split(':')
    return Number(seconds) + Number(minutes) * 60 + Number(hours) * 60 * 60
  }

  const handleMetaUpdate = (e: SyntheticEvent<HTMLAudioElement>) => {
    setCurrentTime(e.currentTarget.currentTime)
    setDuration(e.currentTarget.duration)
  }
  const setPlayerCurrentTime = (time: number) => {
    if (audioRef?.current !== undefined) {
      audioRef!.current!.currentTime = time
    }
  }
  const handleTimeUpdate = (e: SyntheticEvent<HTMLAudioElement>) => {
    setCurrentTime(e.currentTarget.currentTime)
  }
  const togglePlay = () => {
    if (audioRef.current?.paused) {
      setPlaying(true)
      audioRef.current?.play()
    } else {
      setPlaying(false)
      audioRef.current?.pause()
    }
  }

  useEffect(() => {
    const seconds = Number(minutesToSeconds(audioTime))
    if (seconds === 0) {
      return
    }
    audioRef.current?.play()
    setPlaying(true)
    setPlayerCurrentTime(seconds)
    setCurrentTime(seconds)
  }, [audioTime])

  useEffect(() => {
    audioRef.current?.pause()
    setPlaying(false)
    setLoading(false)
    setMuted(false)
    setPlayerCurrentTime(0)
    setDuration(0)
    setCurrentTime(0)
  }, [src])

  const secondsToMinutes = (value: number) => {
    if (value < 3600) {
      return new Date(value * 1000).toISOString().slice(14, 19)
    }
    return new Date(value * 1000).toISOString().slice(11, 19)
  }

  return (
    <Box maxH="48px" overflow="hidden">
      <HStack align="flex-start" width="100%" height="48px">
        <HStack justify="center" p={2} width="100%" height="48px">
          <Text fontSize="14px" pr="4px">
            {secondsToMinutes(currentTime)}
          </Text>
          <Slider
            onChange={setPlayerCurrentTime}
            max={duration || 1}
            value={currentTime}
            min={0}
            aria-label="slider"
            focusThumbOnChange={false}
          >
            <SliderTrack>
              <SliderFilledTrack bg="primary.100" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text fontSize="14px" pl="4px">
            {secondsToMinutes(duration)}
          </Text>
        </HStack>
        <Flex direction="row" justify="center" p={2}>
          <Box
            gridRow="1/4"
            w="32px"
            h="32px"
            padding="4px"
            aria-label="Play toggle"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="50%"
            as={loading ? FaSpinner : playing ? CiPause1 : MdOutlinePlayArrow}
            mr={1}
            onClick={togglePlay}
            cursor="pointer"
            className={loading ? 'icon-spin' : ''}
          />
          <audio
            preload="none"
            ref={audioRef}
            muted={false}
            onTimeUpdate={handleTimeUpdate}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onCanPlay={() => setLoading(false)}
            onLoadedMetadata={handleMetaUpdate}
            src={src}
          >
            Your browser does not support the audio element.
          </audio>
        </Flex>
      </HStack>
    </Box>
  )
}
export default Audio
