import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getProfileBySlug, updateSlug } from '../../api'
import { SlugNotFound } from '../../errors/SlugNotFound'
import { Spinner } from '../Spinner'

import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { User, userState } from '../../store/User'
import { useRecoilState } from 'recoil'
import { PrimaryButton } from '../shared'
import { Logo } from '../Logo'

const MINIMUM_SLUG_LENGTH = 3
export function SelectSlug() {
  const [user, setUser] = useRecoilState<User>(userState)
  const [slug, setSlug] = useState('')
  const [isCheckingSlug, setCheckingSlug] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isSlugValid, setIsSlugValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const email = useMemo(() => user?.email ?? '', [user])
  const possibleSlug = useMemo(() => email.split('@')[0], [email])
  const lastSlug = useRef(possibleSlug)

  useEffect(() => {
    if (possibleSlug && possibleSlug !== lastSlug.current) {
      setSlug(possibleSlug || '')
      console.log('checking slug', possibleSlug, email)
      checkSlug(possibleSlug)
      lastSlug.current = possibleSlug
    }
  }, [possibleSlug])

  const saveSlug = useCallback(async () => {
    try {
      setSaving(true)
      await updateSlug(slug)
      setUser({ ...user, slug })
    } catch (error) {
      setSaving(false)
      throw error
    }
  }, [setUser, slug, user])

  const checkSlug = useCallback(async (slug: string) => {
    if (slug?.length < 3) {
      return
    }

    try {
      await getProfileBySlug(slug)
      setIsSlugValid(false)
      setErrorMessage(`The handle ${slug} has already been taken.`)
    } catch (error) {
      if (error instanceof SlugNotFound) {
        return setIsSlugValid(true)
      }

      setIsSlugValid(false)
    } finally {
      setCheckingSlug(false)
    }
  }, [])

  const debouncedCheckSlug = useMemo(() => debounce(checkSlug, 300), [])

  const onSlugChange = useCallback((event: any) => {
    setSlug(event.target.value)
    setCheckingSlug(true)
    debouncedCheckSlug(event.target.value)
  }, [])

  return (
    <VStack spacing={6} w="400px" align="center">
      <Center>
        <Logo image_width="48px" />
      </Center>
      <Heading fontWeight="bold" fontSize="xl" pb={0} mb={0}>
        Choose a handle
      </Heading>
      <Text p="0 40px" align="center" color="default.100">
        You’re almost there. To begin, create your own, unique Listener handle.
      </Text>
      <FormControl
        isInvalid={slug?.length >= MINIMUM_SLUG_LENGTH && !isSlugValid}
      >
        <InputGroup>
          <InputLeftAddon
            border="1px"
            borderColor="whiteAlpha.300"
            bg="transparent"
            paddingLeft={0}
            children="listener.fm/"
          />
          <Input
            value={slug}
            onChange={onSlugChange}
            autoCapitalize="false"
            autoComplete="false"
          />
          {slug?.length >= MINIMUM_SLUG_LENGTH && (
            <InputRightElement
              children={
                isCheckingSlug ? (
                  <Spinner size="sm" />
                ) : isSlugValid ? (
                  <CheckIcon color="green.500" />
                ) : (
                  <CloseIcon color="red.500" />
                )
              }
            />
          )}
        </InputGroup>

        <Text mt="4" fontSize="sm" color="gray.400">
          This is what you'll share with people in the future to share content.
        </Text>
        {slug?.length >= MINIMUM_SLUG_LENGTH &&
          !isSlugValid &&
          !isCheckingSlug && (
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          )}
      </FormControl>
      <PrimaryButton
        primaryText="Next Step"
        onClick={saveSlug}
        type="submit"
        isDisabled={isCheckingSlug || !isSlugValid}
      />
    </VStack>
  )
}
