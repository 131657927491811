import { Flex, Grid, GridItem, Icon, Show, VStack } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { FaConnectdevelop, FaLock, FaPodcast, FaQuestion } from 'react-icons/fa'

export function SidePanel({ type, children }: { type: string; children: any }) {
  const colorResolver: Record<string, string> = {
    register: 'blue.400',
    notfound: 'red.400',
    login: 'green.400',
    auth: 'yellow.400',
    verify: 'teal.400',
    reset: 'teal.300',
  }
  const iconResolver: Record<string, IconType> = {
    notfound: FaQuestion,
    reset: FaLock,
    verify: FaConnectdevelop,
  }

  return (
    <>
      <Grid templateColumns={['1fr', '1fr', '1fr 2fr']}>
        <Show above="md">
          <GridItem bg={colorResolver[type]}>
            <Flex alignItems="center" justifyContent="center" h="100%">
              <Icon boxSize="10em" as={iconResolver[type] || FaPodcast} />
            </Flex>
          </GridItem>
        </Show>
        <GridItem>
          <Flex
            h="100%"
            alignItems="center"
            justifyContent="center"
            p={8}
            direction="column"
          >
            <VStack spacing="4" width={'full'}>
              {children}
            </VStack>
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}
