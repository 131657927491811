import { atom } from 'recoil'
import { Show } from '../api/ShowApi'
export type User = {
  id?: number
  email?: string
  slug?: string
  firstName?: string
  lastName?: string
  profilePhotoUrl?: string
  podcasts?: any[]
  isVerified?: boolean
  isActive?: boolean
  shows?: Show[]
  localConnection?: any
  billingState?: string
  subscription?: Subscription
  usage?: {
    monthly_podcast_count: number
    show_count: number
  }
}
type Subscription = {
  plan: string
  status: string
  max_shows: number
  max_podcasts: number
}
const userState = atom<User>({
  key: 'userState',
  default: {},
})

const authenticationState = atom({
  key: 'authenticationState',
  default: false,
})

export { userState, authenticationState }
