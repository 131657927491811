import { Button, ButtonProps } from '@chakra-ui/react'

export function PrimaryButton(
  props: {
    isDisabled: boolean
    disabledText?: string
    primaryText?: string
    type?: 'button' | 'submit' | 'reset'
    onClick?: () => void
    children?: any
  } & ButtonProps,
) {
  const {
    isDisabled,
    primaryText,
    disabledText,
    type,
    children,
    ...buttonProps
  } = props
  return (
    <Button
      bg={isDisabled ? 'gray.50' : 'primary.100'}
      color={isDisabled ? 'gray.400' : 'white'}
      _hover={
        isDisabled
          ? {
              cursor: 'not-allowed',
            }
          : {
              bg: 'primary.600',
              color: 'white',
            }
      }
      type={isDisabled ? undefined : type}
      disabled={isDisabled}
      {...buttonProps}
    >
      {children ?? (isDisabled ? disabledText ?? primaryText : primaryText)}
    </Button>
  )
}
