import { Box, Text, TextProps, Image } from '@chakra-ui/react'

export function Logo(props: TextProps & { image_width?: `${number}px` }) {
  const { image_width: width = '25px', ...text_props } = props
  return (
    <Box width={width}>
      <Text {...text_props}>
        <Image width={width} src="/logo.png" />
      </Text>
    </Box>
  )
}
