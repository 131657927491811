import { useOutletContext } from 'react-router-dom'
import { ChangePassword } from '../components/auth/ChangePassword'
import SocialProfile from '../components/profile/SocialProfile'
import { User } from '../store/User'
import { Logout } from './Logout'

export function Settings() {
  const [user]: [Required<User>] = useOutletContext()
  const fullName = `${user.firstName} ${user.lastName}`
  return (
    <SocialProfile
      slug={user.slug}
      image={user?.profilePhotoUrl}
      fullName={fullName}
      firstName={user?.firstName}
      lastName={user?.lastName}
      buttons={[<Logout />, <ChangePassword />]}
    />
  )
}
