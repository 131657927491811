import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { registerUser, RegisterUserDTO } from '../../api'
import { Alert as AlertStore, alertState } from '../../store/Alert'
import { userState } from '../../store/User'
import { PrimaryButton } from '../shared'

export function RenderEmailRegister({ children }: any) {
  const navigate = useNavigate()
  const [, setAlert] = useRecoilState<AlertStore>(alertState)
  const [user, setUser] = useRecoilState(userState)

  const bg = useColorModeValue('white', 'gray.700')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RegisterUserDTO>()
  const text = useColorModeValue('white', 'black')

  const onSubmit = async (data: RegisterUserDTO) => {
    try {
      await registerUser(data)
      setUser({})
      navigate('/dashboard')
    } catch (e: any) {
      if (e.code === AxiosError.ERR_BAD_REQUEST) {
        setAlert({
          status: 'error',
          title: 'Email address already exists!',
          description: (
            <Text>
              Please try a different email address or{' '}
              <Link textDecor={'underline'} href="/login">
                Login here
              </Link>
            </Text>
          ),
        })
        return
      }
      console.log('error!', e)
      setAlert({
        status: 'error',
        title: 'Something went wrong!',
        description: (
          <Text>
            Please contact{' '}
            <Link textDecor={'underline'} href="mailto:support@listener.fm">
              Support
            </Link>
          </Text>
        ),
      })
    }
  }

  return (
    <VStack spacing={8} align="left">
      <Box rounded={'lg'} bg={bg} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl id="email" isInvalid={!!errors.email}>
            <Input
              placeholder="Email Address"
              type="email"
              {...register('email', {
                required: true,
                validate: {
                  maxLength: (v: string) =>
                    v.length <= 50 ||
                    'The email should have at most 50 characters',
                },
              })}
            />
            <FormErrorMessage>
              <>{errors.email?.message}</>
            </FormErrorMessage>
          </FormControl>
          <HStack>
            <FormControl id="first_name" isInvalid={!!errors.first_name}>
              <Input
                placeholder="First name"
                type="text"
                {...register('first_name', {
                  required:
                    'First name field is required, must be a minimum of 3 characters. ',
                  minLength: {
                    message: 'Must be a minimum of 3 characters',
                    value: 3,
                  },
                })}
              />
              <FormErrorMessage>
                <>{errors.first_name?.message}</>
              </FormErrorMessage>
            </FormControl>
            <FormControl id="last_name" isInvalid={!!errors.last_name}>
              <Input
                placeholder="Last name"
                type="text"
                {...register('last_name')}
              />
              <FormErrorMessage>
                <>{errors.last_name?.message}</>
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <FormControl id="password" isInvalid={!!errors.password}>
            <Input
              placeholder="Password"
              type="password"
              {...register('password', {
                required:
                  'Password field is required, must be a minimum of 8 characters. ',
                validate: {
                  matchPattern: (v: string) =>
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
                    'Password must contain at least one uppercase letter, one lowercase letter, and one number',
                },
              })}
            />
            <FormErrorMessage>
              <>{errors.password?.message}</>
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="confirm_password"
            isInvalid={!!errors.confirm_password}
          >
            <Input
              placeholder="Confirm password"
              type="password"
              {...register('confirm_password', {
                required:
                  'Confirm Password field is required, must match password field. ',
                validate: (val: string) => {
                  if (watch('password') !== val) {
                    return 'Your passwords do no match'
                  }
                },
              })}
            />
            <FormErrorMessage>
              <>{errors.confirm_password?.message}</>
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={4}>
            <PrimaryButton
              isDisabled={isSubmitting}
              primaryText="Register"
              disabledText="Registering..."
              type="submit"
            />
            <Center>
              <Link
                as={RouterLink}
                to="/login"
                ml="2"
                color="black"
                fontSize="14px"
              >
                Login
              </Link>
            </Center>
          </Stack>
        </Stack>
      </Box>
    </VStack>
  )
}
