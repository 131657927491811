import {
  Text,
  Link,
  Button,
  TableCaption,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Table,
} from '@chakra-ui/react'
import { Fragment, useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { getRawOutput, ShowNote } from '../PodcastView'
import { RegenerateShowNotes } from '../RegenerateShowNotes'

const renderConcise = (
  uuid: string,
  show_note_idx: number,
  show_note: ShowNote,
  setAudioTime: any,
) => {
  return (
    <Fragment>
      <Td>
        <Link
          key={`${uuid}-concise-show-notes-link-${show_note_idx}`}
          onClick={setAudioTime(show_note.start)}
        >
          {show_note.start}
        </Link>
      </Td>
      <Td>{show_note.concise_summaries![0]}</Td>
    </Fragment>
  )
}
const renderConciseText = (show_note: ShowNote[]) => {
  return show_note
    .map(
      (show_note: ShowNote) =>
        `[${show_note.start}] ${show_note.concise_summaries![0]}\n`,
    )
    .join('')
}

export function ConciseShowNotes({
  setAudioTime,
  renderTitle,
}: {
  setAudioTime: any
  renderTitle: () => JSX.Element
}) {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext)
  const uuid = podcast.fileUuid
  const show_notes = getRawOutput(podcast, 'show_notes')
  if (!show_notes?.[0]?.[0]?.concise_summaries) {
    return null
  }

  const results = (po: ShowNote[]) =>
    po.map((show_note: ShowNote, idx: number) => {
      return <Tr>{renderConcise(uuid, idx, show_note, setAudioTime)}</Tr>
    })

  const table = show_notes
    .map((po: ShowNote[], generationIdx: number) => (
      <>
        <Text as="h3" size="sm" textDecor={'underline'} mt={'5'}>
          Generation #{generationIdx + 1}
        </Text>
        <Thead>
          <TableCaption>
            <CopyToClipboard text={renderConciseText(po)}>
              <Button variant={'outline'}>
                {' '}
                <FaRegCopy />{' '}
                <Text fontSize={'sm'} p={'2'} pr={0}>
                  Copy
                </Text>
              </Button>
            </CopyToClipboard>
          </TableCaption>
          <Tr>
            <Th>Time</Th>
            <Th>Summary</Th>
          </Tr>
        </Thead>
        <Tbody>{results(po)}</Tbody>
      </>
    ))
    .reverse()

  return (
    <Fragment>
      {renderTitle()}
      <RegenerateShowNotes
        type={'show_notes'}
        podcast={podcast}
      ></RegenerateShowNotes>
      <Table>{table}</Table>
    </Fragment>
  )
}
