import {
  Text,
  Button,
  Td,
  Tbody,
  Th,
  Thead,
  Tr,
  TableContainer,
  Table,
  useDisclosure,
  Link,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'
import { Podcast } from '../../../api/types'
import { PodcastPropContext } from '../Context'
import { getRawOutput } from '../PodcastView'
import { Regenerate } from '../Regenerate'
import { EditIcon } from '@chakra-ui/icons'
import { EditSpeakerNames } from '../modal/EditSpeakerNames'

export type QuoteJSON = {
  speaker: number
  startTime: string
  transcript: string
}

export function QuotesJson({ setAudioTime }: { setAudioTime: any }) {
  const { podcast } = useContext<{
    podcast: Podcast<QuoteJSON>
  }>(PodcastPropContext)
  let quotes = getRawOutput(podcast, 'quotes')
  let quotes_json = getRawOutput(podcast, 'quotes_json')
  const host_names = podcast.hostNames ? podcast.hostNames.split(',') : []
  const guest_names = podcast.guestNames ? podcast.guestNames.split(',') : []
  const [names, setNames] = useState<string[]>([...host_names, ...guest_names])
  const { isOpen, onOpen, onClose } = useDisclosure()
  if (!quotes_json && quotes) {
    return (
      <Regenerate
        type={'quotes'}
        podcast={podcast}
        placeholder="Enter prompt for quotes"
        buttonText="Regenerate Quotes"
      />
    )
  }
  if (!quotes_json) {
    return null
  }
  const getNumberToSpeaker = (speaker_number: number) => {
    const speaker_name = names[speaker_number - 1]
    if (!speaker_name) {
      return speaker_number
    }

    return speaker_name.trim()
  }
  const results = (po: QuoteJSON[]) =>
    po?.map((quote: QuoteJSON, idx: number) => (
      <Tr>
        <Td>
          <Link onClick={setAudioTime(quote.startTime)}>{quote.startTime}</Link>
        </Td>
        <Td>{getNumberToSpeaker(quote.speaker)}</Td>
        <td>{quote.transcript}</td>
        <Td>
          <CopyToClipboard
            text={`${quote.startTime} ${quote.transcript} - ${quote.speaker}`}
          >
            <Button variant={'outline'}>
              {' '}
              <FaRegCopy />{' '}
              <Text fontSize={'sm'} p={'2'} pr={0}>
                Copy
              </Text>
            </Button>
          </CopyToClipboard>
        </Td>
      </Tr>
    ))

  const table = quotes_json!
    .map((po: QuoteJSON[], generationIdx: number) => (
      <>
        <Text as="h3" size="sm" textDecor={'underline'} mt={'5'}>
          Generation #{generationIdx + 1}
        </Text>
        <TableContainer>
          <Table
            variant="simple"
            layout={'fixed'}
            width={'100%'}
            whiteSpace={'pre-wrap'}
          >
            <Thead>
              <Tr>
                <Th width={'15%'}>Time</Th>
                <Th width={'15%'}>
                  Speaker{' '}
                  <Button
                    variant={'ghost'}
                    size={'sm'}
                    onClick={() => {
                      onOpen()
                    }}
                  >
                    <EditIcon></EditIcon>
                  </Button>
                </Th>
                <Th width={'55%'}>Quote</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>{results(po)}</Tbody>
          </Table>
          <EditSpeakerNames
            onClose={onClose}
            isOpen={isOpen}
            onOpen={onOpen}
            names={names}
            setNames={setNames}
          />
        </TableContainer>
      </>
    ))
    .reverse()
  return (
    <>
      <Regenerate
        type={'quotes'}
        podcast={podcast}
        placeholder="Enter prompt for quotes"
        buttonText="Regenerate Quotes"
      />
      {table}
    </>
  )
}
