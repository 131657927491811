import { AlertStatus } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { atom } from 'recoil'
export type Alert = {
  status: AlertStatus
  title: string
  description: ReactNode
} | null
const alertState = atom<Alert>({
  key: 'alertState',
  default: null,
  effects: [({ resetSelf }) => {
    setTimeout(() => {
      resetSelf()
    }, 5000);
  }]
})

export { alertState }
