import { atom } from 'recoil'
import { Uploader } from '../utilities/Uploader'
const uploaderState = atom<Uploader>({
  key: 'uploaderState',
  default: undefined,
  dangerouslyAllowMutability: true

})

export { uploaderState }
