import {
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Flex,
  Text,
  Center,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { Podcast } from '../../api/types'
import {
  RegenerateOutput,
  regenerateOutputState,
} from '../../store/RegenerateOutput'
import { Regenerate } from './Regenerate'

export function RegenerateShowNotes({
  type,
  podcast,
}: {
  type: string
  podcast: Podcast
}) {
  const [sliderValue, setSliderValue] = useState(10)
  const [regenerateOutput, _] = useRecoilState<RegenerateOutput>(
    regenerateOutputState,
  )

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

  return (
    <Flex direction={'column'}>
      {!regenerateOutput?.generating && (
        <Box pt={6} pb={6} width={'xs'} alignSelf={'center'}>
          <Center>Number of timestamps</Center>
          <Slider
            aria-label="slider-ex-6"
            onChangeEnd={(val) => setSliderValue(val)}
            min={5}
            max={20}
            defaultValue={sliderValue}
            step={5}
            mt={5}
          >
            <SliderMark value={5} {...labelStyles}>
              5
            </SliderMark>
            <SliderMark value={10} {...labelStyles}>
              10
            </SliderMark>
            <SliderMark value={15} {...labelStyles}>
              15
            </SliderMark>
            <SliderMark value={20} {...labelStyles}>
              20
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Box>
      )}
      <Regenerate
        type={type}
        podcast={podcast}
        showNoteChunks={sliderValue}
        placeholder="Specify a prompt for the show notes"
        buttonText="Regenerate Show Notes"
      />
    </Flex>
  )
}
