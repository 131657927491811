import {
  Box,
  Center,
  Image,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { RenderEmailRegister } from './RenderEmailRegister'
import { RenderFacebookForm } from './RenderFacebookForm'
import { RenderGoogleForm } from './RenderGoogleForm'
import { RenderTwitterForm } from './RenderTwitterForm'
import { TestimonialCard } from '../TestimonialCard'
export function Register() {
  return (
    <Grid templateColumns="repeat(2, 1fr)">
      <GridItem w="100%">
        <Center h="100%">
          <Box maxW="md" p={8}>
            <VStack spacing={4} w="440px" align="left">
              <Center>
                <Image
                  width="48px"
                  borderRadius="50%"
                  src="/logo.png"
                  boxShadow="md"
                />
              </Center>
              <Center>
                <Box padding="0 32px">
                  <Center>
                    <Heading letterSpacing="2px" fontSize="2xl">
                      Register with Listener
                    </Heading>
                  </Center>
                  <Center>
                    <Text mt="4" fontSize="sm" color="gray.400" align="center">
                      Welcome to Listener.fm! We're so excited you're here.
                      Register with one of our provider partners to continue.
                    </Text>
                  </Center>
                </Box>
              </Center>
              <RenderFacebookForm>Register with Facebook</RenderFacebookForm>
              <RenderTwitterForm>Register with Twitter</RenderTwitterForm>
              <RenderGoogleForm>Register with Google</RenderGoogleForm>
              <Center>
                <Text color="gray.400" align="center">
                  Or
                </Text>
              </Center>
              <RenderEmailRegister>Register with Email</RenderEmailRegister>
            </VStack>
          </Box>
        </Center>
      </GridItem>
      <GridItem w="100%" h="100%" bg="#EAFAFA">
        <VStack h="100%">
          <Center h="80%">
            <TestimonialCard />
          </Center>
          <Box w="100%" h="20%">
            <Center>
              <VStack>
                <HStack spacing={4}>
                  <Image width="72px" height="72px" src="/group chat.png" />
                  <Image width="72px" height="72px" src="/shining.png" />
                  <Image width="72px" height="72px" src="/cs.png" />
                  <Image width="72px" height="72px" src="/meltzer.png" />
                  <Image width="72px" height="72px" src="/casey_adams.png" />
                </HStack>
                <Text color="gray.500">
                  Join Thousands of Podcasters already Elevating Their
                  Post-Production
                </Text>
              </VStack>
            </Center>
          </Box>
        </VStack>
      </GridItem>
    </Grid>
  )
}
