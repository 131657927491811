import {
  Text,
  Popover,
  PopoverTrigger,
  Avatar,
  Portal,
  PopoverContent,
  PopoverBody,
  HStack,
  VStack,
  Divider,
  Icon,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { CiEdit, CiStar, CiGift } from 'react-icons/ci'
import { FaInstagram, FaXTwitter } from 'react-icons/fa6'
import { MdHelpOutline, MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { ChangePassword } from '../auth/ChangePassword'

export function ProfileContext({ user }: { user: any }) {
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  const toggleIsChangingPassword = useCallback(() => {
    setIsChangingPassword((x) => !x)
  }, [])

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Popover>
      <PopoverTrigger>
        <Avatar
          src={user.profilePhotoUrl}
          w="32px"
          h="32px"
          marginLeft="12px"
          cursor="pointer"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="230px">
          <PopoverBody>
            <VStack align="flex-start">
              <HStack>
                <Avatar src={user.profilePhotoUrl} h="36px" w="36px" />
                <VStack
                  align="flex-start"
                  h="36px"
                  spacing="0"
                  justifyContent="space-between"
                  pl="4px"
                >
                  <Text fontSize="sm" lineHeight="18px">
                    {user.firstName} {user.lastName}
                  </Text>
                  <Text fontSize="sm" lineHeight="18px" color="gray.400">
                    {user.email}
                  </Text>
                </VStack>
              </HStack>
              <Divider />

              {user.subscription?.plan === 'appsumo' ? (
                <Link to={`/appsumo?email=${user.email}`}>
                  <HStack h="36px" cursor="pointer" w="230px">
                    <Icon fill="gray.500" as={CiGift} w="20px" h="20px" />
                    <Text fontSize="sm">Stack Codes</Text>
                  </HStack>
                </Link>
              ) : null}
              <Link to="/subscribe">
                <HStack h="36px" cursor="pointer" w="230px">
                  <Icon fill="gray.500" as={CiStar} w="20px" h="20px" />
                  <Text fontSize="sm">Manage your subscription</Text>
                </HStack>
              </Link>
              <Link to="" onClick={onOpen}>
                <HStack h="36px" cursor="pointer" w="230px">
                  <Icon fill="gray.500" as={CiEdit} w="20px" h="20px" />
                  <Text fontSize="sm">Change your password</Text>
                </HStack>
              </Link>
              <Link to="" onClick={() => (window as any).tidioChatApi.open()}>
                <HStack h="36px" cursor="pointer" w="230px">
                  <Icon fill="gray.500" as={MdHelpOutline} w="20px" h="20px" />
                  <Text fontSize="sm">Help</Text>
                </HStack>
              </Link>

              <Divider />

              <Link to="https://twitter.com/ListenerPods" target="_blank">
                <HStack h="36px" cursor="pointer" w="230px">
                  <Icon fill="gray.500" as={FaXTwitter} w="20px" />
                  <Text fontSize="sm">Follow us on X (Twitter)</Text>
                </HStack>
              </Link>
              <Link to="https://www.instagram.com/listener/" target="_blank">
                <HStack h="36px" cursor="pointer" w="230px">
                  <Icon fill="gray.500" as={FaInstagram} w="20px" />
                  <Text fontSize="sm">Follow us on Instagram</Text>
                </HStack>
              </Link>
              <Divider />
              <Link to="/logout">
                <HStack h="36px" cursor="pointer" w="230px">
                  <Icon fill="gray.500" as={MdLogout} w="20px" />
                  <Text fontSize="sm">Log out</Text>
                </HStack>
              </Link>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ChangePasswordModal onClose={onClose} />
            </Modal>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

function ChangePasswordModal({ onClose }: any) {
  return (
    <ModalContent>
      <ModalHeader>Change your password</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <ChangePassword />
      </ModalBody>
    </ModalContent>
  )
}
