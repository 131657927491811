import { HStack, Image, Text, Link } from '@chakra-ui/react'
import { Show } from '../../api'
import { useRecoilState } from 'recoil'
import { showState } from '../../store/Show'
import { Link as RouterLink } from 'react-router-dom'
import { TopNav } from '../shared/TopNav'

export function ShowNav({ shows }: { shows: Show[] | undefined }) {
  const [currentShow] = useRecoilState<Show>(showState)
  if (!shows) {
    return null
  }

  return (
    <TopNav
      selected_id={currentShow?.id}
      items={shows?.map((x) => ({
        id: x.id,
        link: `/dashboard/${x.id}`,
        item: x,
      }))}
      Content={({ item }) => (
        <>
          <Image w="20px" h="20px" src={item.image} borderRadius="4px" />
          <Text maxH="32px" fontSize="14px">
            {item.name}
          </Text>
        </>
      )}
      TrailingComponent={() => (
        <Link
          as={RouterLink}
          color="gray.500"
          flexShrink="0"
          fontSize="14px"
          to="/onboarding"
        >
          + Add a podcast
        </Link>
      )}
    />
  )
}
