import axios from 'axios'
import { SlugNotFound } from '../errors/SlugNotFound'

export type RegenerateDTO = {
  type: string
  fileUUID: string
  prompt: string
  showNoteChunks?: number
}

export type RegisterAppSumoDTO = {
  email: string
  first_name?: string
  last_name?: string
  password?: string
  confirm_password?: string
  appsumo_code: string
}

export type RegisterUserDTO = {
  email: string
  first_name: string
  last_name?: string
  password: string
  confirm_password: string
}

export type ChangePasswordDTO = {
  current_password: string
  password: string
  confirm_password: string
}

export type ChangePasswordWithResetDTO = {
  reset_hash: string
  password: string
  confirm_password: string
}

export type LoginDTO = {
  email: string
  password: string
}

export type ResetPasswordDTO = {
  email: string
}

export const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

export const getProfile = async () => {
  const { data } = await axios_instance.get('profile')

  return data
}

export const regenerate = async (regenerateParams: RegenerateDTO) => {
  const { data } = await axios_instance.post(
    `gpt/regenerate/${regenerateParams.type}`,
    {
      fileUUID: regenerateParams.fileUUID,
      prompt: regenerateParams.prompt,
      timestamp_chunks: regenerateParams.showNoteChunks,
    },
  )

  return data
}
export const searchListenNotes = async (query: string) => {
  const { data } = await axios_instance.get(`listennotes/search`, {
    params: { query },
  })

  return data
}

export const getProfileBySlug = async (slug: string) => {
  try {
    const { data } = await axios_instance.get(`${slug}/profile`)
    return data
  } catch (error: any) {
    if (error?.response?.status === 404) {
      throw new SlugNotFound(error.message)
    }

    throw error
  }
}

export const updateSlug = async (slug: string) => {
  const { data } = await axios_instance.put(`/profile/slug`, {
    slug,
  })
  return data
}

export const loginWithFacebookCode = async (code: string) => {
  const { data } = await axios_instance.get(`/auth/facebook/callback`, {
    params: { code },
  })
  return data
}

export const loginWithGoogleCode = async (params: string) => {
  const { data } = await axios_instance.get(`/auth/google/callback?${params}`)
  return data
}

export const loginWithTwitterCode = async (params: string) => {
  const { data } = await axios_instance.get(`/auth/twitter/callback?${params}`)
  return data
}

export const logout = async () => {
  const { data } = await axios_instance.get(`/auth/logout`)
  return data
}

export const registerUser = async (user: RegisterUserDTO) => {
  const { data } = await axios_instance.post(`/auth/register`, user)
  return data
}

export const registerUserWithAppSumo = async (user: RegisterAppSumoDTO) => {
  const response = await axios_instance.post(`/auth/appsumo`, user, {
    validateStatus: () => true,
  })

  if (response.status >= 300 || response.status < 200) {
    return {
      error: response.data,
    }
  }

  return response.data
}

export const loginWithEmail = async (user: LoginDTO) => {
  const { data } = await axios_instance.post(`/auth/local/login`, user)
  return data
}

export const resetPassword = async ({ email }: ResetPasswordDTO) => {
  const { data } = await axios_instance.post(`/auth/local/reset/initiate`, {
    email,
  })
  return data
}

export const changePasswordViaReset = async (
  password_data: ChangePasswordWithResetDTO,
) => {
  const { data } = await axios_instance.post(`/auth/local/reset`, password_data)
  return data
}

export const changePassword = async (password_data: ChangePasswordDTO) => {
  const { data } = await axios_instance.post(
    `/auth/local/change`,
    password_data,
  )
  return data
}
