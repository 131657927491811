import { Button, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
export function RenderGoogleForm({ children }: any) {
  const [isLoading, setLoading] = useState(false)
  const loginWithGoogle = async () => {
    setLoading(true)
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/login?referral=`
  }

  return (
    <VStack spacing={8} align="left">
      <Button
        w="100%"
        isLoading={isLoading}
        onClick={() => loginWithGoogle()}
        background="white"
        borderWidth="1px"
        boxShadow="sm"
        leftIcon={<FcGoogle />}
      >
        {children}
      </Button>
    </VStack>
  )
}
