import { Text, Button, Td, Tbody, Th, Thead, Tr, TableContainer, Table } from "@chakra-ui/react"
import { useContext } from "react";
import CopyToClipboard from "react-copy-to-clipboard"
import { FaRegCopy } from "react-icons/fa"
import { Podcast } from "../../../api/types";
import { PodcastPropContext } from "../Context";
import { getRawOutput } from "../PodcastView";
import { Regenerate } from "../Regenerate";

export type QuoteJSON = {
  speaker: number
  startTime: string
  transcript: string
}

export function Quotes() {
  const { podcast } = useContext<{
    podcast: Podcast<string>
  }>(PodcastPropContext);
  let quotes = getRawOutput(podcast, 'quotes')
  if (!quotes) {
    return null
  }

  const results = (po: string[]) => po?.map((quote: string, idx: number) => {
    return (
      <Tr>
        <Td>{idx + 1}</Td>
        <Td>
          {quote}
        </Td>
        <Td >
          <CopyToClipboard text={quote as string}>
            <Button variant={"outline"}> <FaRegCopy /> <Text fontSize={"sm"} p={"2"} pr={0}>Copy</Text></Button >
          </CopyToClipboard>
        </Td>
      </Tr>)
  })

  const table = quotes!.map((po: string[], generationIdx: number) => <>
    <Text as="h3" size="sm" textDecor={"underline"} mt={"5"}>
      Generation #{generationIdx + 1}
    </Text>
    <TableContainer>
      <Table variant='simple' layout={"fixed"} width={"100%"} whiteSpace={"pre-wrap"}>
        <Thead>
          <Tr>
            <Th width={"5%"}>#</Th>
            <Th width={"85%"}>Quote</Th>
            <Th width={'10%'}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {results(po)}
        </Tbody>
      </Table>
    </TableContainer>
  </>).reverse()
  return (<>
    {table}
  </>)
}