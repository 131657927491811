import { atom } from 'recoil'
export type RegenerateOutput = {
  type: string
  generating: boolean
}
const regenerateOutputState = atom<RegenerateOutput>({
  key: 'regenerateOutputState',
  default: undefined,
})

export { regenerateOutputState }
