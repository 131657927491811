import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  VStack,
  Image,
  Text,
  FormControl,
  Tooltip,
  HStack,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Heading,
  Center,
} from '@chakra-ui/react'
import { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { FaFile } from 'react-icons/fa'
import { useRecoilState } from 'recoil'
import { uploaderState } from '../store/Uploader'
import { Uploader } from '../utilities/Uploader'
import { BsInfoCircleFill } from 'react-icons/bs'

export const Upload = ({
  onCompleteFn,
  onErrorFn,
}: {
  onCompleteFn: any
  onErrorFn: any
}) => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const [uploader, setUploader] = useRecoilState<Uploader>(uploaderState)
  const [percentage, setPercentage] = useState('0')

  const name = 'podcast_file'

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (file) {
      let percentage: string
      let overriddenOnCompleteFn = (data: any) => {
        setFile(undefined)
        setPercentage('0')
        onCompleteFn(data)
      }
      const videoUploaderOptions = {
        file: file,
        onCompleteFn: overriddenOnCompleteFn,
      }
      const _uploader = new Uploader(videoUploaderOptions) as any

      _uploader
        .onProgress(({ percentage: newPercentage }: { percentage: any }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            setPercentage(newPercentage)
          }
        })
        .onError((error: Error) => {
          setFile(undefined)
          onErrorFn(error)
        })
      setUploader(_uploader)
    }
  }, [file])

  const onCancel = useCallback(() => {
    if (uploader) {
      uploader.abort()
      setUploader(uploader)
      setFile(undefined)
    }
  }, [setUploader, uploader])

  return (
    <FormControl isRequired>
      <FormHelperText>
        {file && uploader?.fileId && (
          <Box display={'inline-block'} width={'100%'}>
            <Progress hasStripe value={Number(percentage)} />
          </Box>
        )}
        {file && (
          <Box display={'inline-block'} width={'100%'}>
            <FormLabel>File Name</FormLabel>
            <HStack>
              <Text>{file?.name}</Text>
              <DeleteIcon onClick={onCancel} cursor={'pointer'} mx={2} />
            </HStack>
          </Box>
        )}
      </FormHelperText>
      {!file && (
        <>
          <InputGroup>
            <Center
              w="400px"
              h="230px"
              border="2px dashed"
              borderRadius="8px"
              borderColor="gray.100"
              mt="12px"
              cursor="pointer"
              onClick={() => inputRef.current!.click()}
            >
              <VStack pb="20px">
                <Image w="60px" mt="20px" src="/note.png" />
                <Text>Select a file</Text>
                <Text color="default.100">File size limit is 1GB</Text>
                <Tooltip
                  label="File types accepted are: MP3, MP4, MP2, AAC, WAV, FLAC, PCM, M4A, Ogg, Opus, and WebM."
                  mb="10px"
                >
                  <HStack bg="gray.100" borderRadius="100px" padding="2px 8px">
                    <Icon w="10px" fill="gray.500" as={BsInfoCircleFill} />
                    <Text color="gray.500">Supported file types</Text>
                  </HStack>
                </Tooltip>
              </VStack>
            </Center>
            <input
              type="file"
              onChange={(e) => setFile(e.target?.files?.[0])}
              accept="audio/*"
              ref={inputRef}
              name={name}
              style={{ display: 'none' }}
            />
          </InputGroup>
        </>
      )}
    </FormControl>
  )
}
